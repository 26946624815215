// Utilities
@import "styles/utilities/variables";
@import "styles/utilities/animations";

.error {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    flex: 1 1 0;
    min-height: 100vh;
    padding: 50px 0;
    color: #fff;

    img {
        margin-bottom: 50px;
    }

    h1 {
        font-size: 45px;
    }

    h2 {
        font-size: 20px;
        font-family: "Inter", sans-serif;
        font-weight: 500;
        margin-bottom: 50px;
    }
}