// Utilities
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "styles/utilities/variables";

.catalogCard {
    background-color: $fifth-color;
    padding: 45px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
    transition: $transition;
    border-radius: 5px;

    &:hover {
        transform: scale(1.02);
    }

    &Title {
        text-align: center;
        font-family: Montserrat, sans-serif;
        color: #3B3B3B;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        line-height: 1em;
    }

    &Pic {
        margin-top: 30px;
        margin-bottom: 30px;
        width: 50%;
        height: 100px;
    }

    &Break {
        margin: 20px 0 0;
        width: 72px;
        height: 2px;
        background: #ffb160;
    }
}
