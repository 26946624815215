@import "styles/utilities/variables";

.alert {
    background: #ffe5c8;
    color: #90581a;
    padding: 25px 30px !important;
    border-radius: 1rem;

    p {
        margin: 0;
    }

    p + p {
        margin-top: 8px;
    }

    button {
        margin-top: 25px;
        width: 100%;
    }
}