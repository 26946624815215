// Utilities
@import "styles/utilities/variables";
@import "styles/utilities/animations";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.main {
    &Layout {
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 100vh;

        &Content {
            flex-grow: 1;
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;

            @include media-breakpoint-down($mobile-grid) {
                flex-direction: column;
            }
        }
    }

    &Content {
        background-color: $sixth-color;
        border-radius: 20px;
        box-shadow: 0 10px 0 0 rgba(255, 255, 255, 0.2);
        margin-right: 30px;
        position: relative;
        flex: 1 1 0;
        min-height: 88vh;
        overflow: hidden;

        @include media-breakpoint-down($mobile-grid) {
            margin-right: 0;
            width: 100%;
            min-height: unset;
            flex: 1 1 0;
        }
    }

    &Footer {
        color: grey;
        font-size: 12px;
        margin: 20px 0 5px 130px;
        font-weight: 500;

        @include media-breakpoint-down($mobile-grid) {
            margin: 20px;
        }
    }

    &Navigation {
        max-width: 130px !important;
        flex: 1 0 0;

        @include media-breakpoint-down($mobile-grid) {
            display: flex;
            align-items: center;
            max-width: unset !important;
            justify-content: space-between;
            width: 100%;
            padding: 30px 25px;
            flex: unset;
        }

        &Logo {
            position: relative;

            @include media-breakpoint-down($mobile-grid) {
                z-index: 9999;
            }

            img {
                max-width: 72px;
                display: block;
                margin: 15px auto 0;

                @include media-breakpoint-down($mobile-grid) {
                    margin-top: 0;
                }
            }

            span {
                background: #dbdfe0;
                color: #46515d;
                padding: 2px 15px 0px;
                text-transform: uppercase;
                font-weight: 600;
                font-family: Poppins, sans-serif;
                border-radius: 3px;
                position: absolute;
                left: 28px;
                bottom: -52px;
                border-bottom: 3px solid #9ea1a1;
                box-sizing: border-box;

                @include media-breakpoint-down($mobile-grid) {
                    display: none;
                }

                &:before {
                    width: 30px;
                    height: 30px;
                    border: 1px solid #576574;
                    content: '';
                    top: -15px;
                    position: absolute;
                    z-index: -1;
                    left: 20px;
                    transform: rotate(45deg);
                }

                &:after {
                    width: 5px;
                    height: 5px;
                    border-radius: 2px;
                    background: #53606e;
                    content: '';
                    position: absolute;
                    top: -22px;
                    transform: translateX(-50%);
                    left: 50%;
                }
            }
        }

        &Container {
            list-style: none;
            margin: 90px 0 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            li {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                padding: 10px 0;
                background: transparent;
                transition: $transition-base;

                img {
                    width: 38px;
                    display: block;
                    margin: 0 auto 8px;
                }

                p {
                    font-family: "Panton", sans-serif;
                    text-transform: uppercase;
                    color: #fff;
                    font-weight: 700;
                    opacity: 80%;
                    font-size: 13px;
                    margin: 0;
                    transition: $transition-base;
                }
            }

            li + li {
                margin-top: 15px;
            }
        }

        &ItemActive {
            background: #fafafa !important;

            p {
                color: #355FA3 !important;
                opacity: 1 !important;
            }
        }

        &MobileHeader {
            display: none;
        }

        @include media-breakpoint-down($mobile-grid) {
            &Container:not(&Opened) {
                visibility: hidden;
            }

            &Container {
                position: fixed;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 9998;
                background: #303437;
                justify-content: flex-start;
                padding-top: 50px;
                transform: translateY(-50px);
                opacity: 0;
                transition: $transition;
            }

            &Opened {
                transform: translateY(0);
                opacity: 100%;
                visibility: visible;
            }

            &Toggle {
                border: none !important;
                padding: 0 !important;
                width: 55px;
                height: 55px;
                outline: none !important;
                box-shadow: none !important;
                color: #fff !important;
                transition: all 200ms ease-in-out !important;
                background: transparent !important;
                margin-left: 20px;
                position: relative;

                &Opened {
                    color: $primary-color !important;
                }

                &Icon {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    width: 40px;
                }
            }

            &MobileHeader {
                display: flex;
                align-items: center;
                z-index: 9999;
                position: relative;

                &Faq {
                    width: 38px;
                }
            }
        }
    }

}

.accountAlert {
    margin-left: 130px;
    background: #db8c10;
    color: #fff;
    align-self: flex-start;
    display: flex;
    align-items: center;
    border-radius: 0 0 12px 12px;
    padding: 6px 15px 8px;

    svg {
        font-size: 22px;
        margin-right: 10px;
    }

    p {
        margin: 0;
        line-height: 1;
        font-family: Inter, sans-serif;
        font-weight: 500;
    }

    a {
        color: #943b00;
        margin-left: 8px;
    }
}

.mainNav {
    min-width: 230px;
}

:global .navbar-toggler {
    border: none !important;
    position: absolute;
    top: 16px;
    right: 0;
    padding: 0 !important;
    width: 45px;
    height: 45px;
    outline: none !important;
    box-shadow: none !important;
    color: #fff !important;
    transition: all 200ms ease-in-out !important;

    &.toggled {
        color: $primary-color !important;
    }

    &-icon {
        position: absolute;
        top: 0;
        right: 0;
    }
}

.badge {
    min-width: 20px;
    padding: 0 8px;
    height: 20px;
    color: #fff !important;
    font-weight: bold;
    text-align: center;
    line-height: 20px !important;
    background-color: #e55039;
    border-radius: 10px;
    margin-left: 12px;
    position: relative;

    &:after {
        content: '';
        background: #ff7979;
        border-radius: 20px;
        height: 26px;
        width: 26px;
        top: -3px;
        left: -3px;
        position: absolute;
        opacity: 0;
        z-index: -1;

        @include animate(pulse, 3s, true) {
            0% {
                -webkit-transform: scale(0.1, 0.1);
                opacity: 0.0;
            }
            30% {
                opacity: 0.7;
            }
            60% {
                -webkit-transform: scale(1.2, 1.2);
                opacity: 0.0;
            }
        }
    }
}

.navLogo {
    margin-bottom: 7rem;
    display: block;
    position: relative;

    img {
        display: block;
        margin-left: 1.5rem;
    }

    span {
        background: #dbdfe0;
        color: #46515d;
        padding: 2px 15px 0px;
        text-transform: uppercase;
        font-weight: 600;
        font-family: Poppins, sans-serif;
        border-radius: 3px;
        position: absolute;
        left: 74px;
        bottom: -57px;
        border-bottom: 3px solid #9ea1a1;
        box-sizing: border-box;

        &:before {
            width: 30px;
            height: 30px;
            border: 1px solid #576574;
            content: '';
            top: -15px;
            position: absolute;
            z-index: -1;
            left: 20px;
            transform: rotate(45deg);
        }

        &:after {
            width: 5px;
            height: 5px;
            border-radius: 2px;
            background: #53606e;
            content: '';
            position: absolute;
            top: -22px;
            transform: translateX(-50%);
            left: 50%;
        }
    }
}

//mobile secondary navigation
:global nav#subNav {
    padding: 0 18px;
    flex-grow: 0;
    position: relative;
    z-index: 3;
    display: none;
}

//laptop
@include media-breakpoint-up(sm) {
    .topnav {
        height: $top-nav-height;
        display: flex;
        align-items: center;
        padding-right: 2rem !important;

        .itemText + .item {
            margin-left: 40px;
        }

        .itemText {
            margin: 0 0 0 auto;
            color: #fff;
            font-size: 0.95em;
            font-weight: 500;
            opacity: 95%;
        }

        .item {
            margin-left: auto;
            width: $top-nav-icon-size;
            height: $top-nav-icon-size;
            cursor: pointer;
        }

        .item + .item {
            margin-left: 25px;
        }
    }
}

//mobile
@include media-breakpoint-down(xl) {
    .mainContent {
        flex-grow: 1;
        margin-bottom: 20px;
        border-radius: 15px;
        overflow-y: visible;
        box-shadow: 0 7px 0 0 rgba(255, 255, 255, 0.2);
    }

    :global nav#subNav.used {
        display: block;

        & + :local.mainContent {
            margin-top: -20px !important;
        }
    }

    :global .navbar {
        flex-grow: 0;
        height: 135px;
    }

    .layoutContent {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        flex-grow: 1;
    }

    .navLogo {
        margin: 35px auto 0 auto;;
        display: block;

        img {
            display: block;
            margin: 0;
        }
    }

    :global .sectionTitle {
        color: $seventh-color;
        position: relative;
        top: -85px;
        text-align: center;
    }

    .navMenu {
        position: fixed;
        height: 100vh;
        background: #303436;
        top: 120px;
        left: 0;
        z-index: 999;
        padding-top: 80px;
        overflow: hidden !important;
        width: 100%;

        :global .nav-item + .nav-item {
            margin-top: 5vh;
        }

        &:global.collapse.show {
            @include animate(navIn, 250ms) {
                from {
                    height: 0;
                    opacity: 0;
                }
                to {
                    opacity: 1;
                    height: 100vh;
                }
            }
        }

        .navLink {
            text-align: center;

            & > svg {
                display: block;
                width: 48px;
                height: auto;
                color: #fff;
                margin: 0 auto 15px auto;
            }

            & > span {
                font-size: 22px;
            }
        }
    }

    .topnav {
        display: none;
    }

    .ul {
        max-height: calc(100% - 130px);
        overflow-y: auto;
    }
}

.ul,
.li {
    font-weight: 500;
    font-family: "Poppins";
    width: 100%;

    @include media-breakpoint-up(xl) {
        font-size: 12px;
    }

    & + & {
        margin-top: 10px;
    }
}

.li {
    @include media-breakpoint-down(xl) {
        width: 90%;
        margin: 0 auto;
    }
}

.navMenu {
    @include media-breakpoint-up(xl) {
        margin-top: 50px;
    }

    &:global.collapsing {
        -webkit-transition: none;
        transition: none;
        display: none;
    }
}

.navLink {
    position: relative;

    span {
        color: $sixth-color;
    }
}

.linkLogo {
    margin: 0 10px 0 20px;
    width: 30px;
}

.navLink .linkLogo {
    fill: white;
}

.tabFooter {
    display: none;
}

@include media-breakpoint-down(xl) {
    .navLogo {
        span {
            bottom: unset;
            left: 50%;
            transform: translateX(-50%);
            top: -42px;

            &:before, &:after {
                display: none;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .tabFooter {
        display: block;
        margin: 15px;
        position: absolute;
        bottom: 0;
        color: grey;
        font-size: 12px;
    }

    :global .nav-item :local .navLink {
        transition: none;
        padding: 0;
        height: $nav-height;
        display: flex;
        align-items: center;

        span {
            line-height: 12px;
            margin-top: 0.125em;
        }

        &:before, &:after {
            position: absolute;
            content: '';
            right: 0;
            height: $nav-corner-size;
            width: $nav-corner-size;
            background: transparent;
            display: none;
            z-index: -1;
        }

        &:before {
            top: -$nav-corner-size;
            border-radius: 0 0 $nav-corner-size 0;
            box-shadow: 10px 10px 0 10px $sixth-color;
        }

        &:after {
            bottom: -$nav-corner-size;
            border-radius: 0 $nav-corner-size 0 0;
            box-shadow: 10px -10px 0 10px $sixth-color;
        }
    }

    :global .active :local .navLink {
        background-color: $sixth-color;
        border-radius: 20px 0 0 20px;

        span {
            color: $fourth-color;
        }

        .linkLogo {
            fill: black;
        }

        &:before, &:after {
            display: block;
        }
    }
}

.notification {
    position: relative;

    img {
        width: 100%;
        max-width: 21px;
        max-height: 21px;
    }
}

.haveNotification {
    position: relative;

    &:after {
        width: 6px;
        height: 6px;
        background: #E8523F;
        border-radius: 3px;
        border: 1px solid #fff;
        content: '';
        top: 5px;
        right: 2px;
        position: absolute;
    }
}