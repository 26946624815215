@import "styles/utilities/variables";

.uploader {
    position: relative;

    &Titled &Container {
        padding-top: 5px;
    }

    input {
        display: none;
    }

    &Icon {
        font-size: 17px;
        opacity: 60%;
        margin-right: 12px;
    }

    &Container {
        display: flex;
        align-items: center;

        label {
            flex: 1 1 0;
            cursor: pointer;
            transition: $transition;
        }
    }

    &Delete {
        cursor: pointer;
        font-size: 17px;
        transition: $transition;

        &:hover {
            color: $danger-color;
        }
    }

    &Preview {
        font-size: 17px;
        color: $second-color;
        margin-right: 8px;
    }

    &Label {
        &Empty {
            color: $third-font-color;

            &:hover {
                color: $seventh-color;
            }
        }
    }
}