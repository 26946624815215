@import "styles/utilities/variables";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.title {
  text-transform: uppercase;
  font-family: "Panton", sans-serif;
  font-size: 24px;
}

.profileContainer {
  margin-top: 25px;
  z-index: 1;
  position: relative;

  p {
    margin: 0;
    line-height: 32px;
  }
}

@include media-breakpoint-up(lg) {
  .profileInfo {
    background: #fff;
    box-shadow: 0 2px 10px 4px rgba(0, 0, 0, 0.03);
    border-radius: 12px;
    padding: 20px 25px !important;
  }
}

.profileButton {
  background: #FBB228;
  height: 42px;
  line-height: 42px;
  text-align: center;
  padding: 0;
  margin: 0;
  outline: 0;
  box-shadow: none;
  border: none;
  color: #fff;
  display: block;
  width: 320px;
  border-radius: 25px;
  font-weight: 500;
  font-family: "inter";

  svg {
    margin-right: 8px;
  }

  & + & {
    margin-top: 10px;
  }
}

.updateLink {
  font-family: "Panton", sans-serif !important;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-block;
  margin-top: 30px;
  font-size: 0.9em;

  img {
    width: 24px;
    vertical-align: -7px;
    margin-right: 5px;
  }
}

.profileLanguage {
  margin-bottom: 40px;
}

.languageSelect {
  width: 320px;

  :global .language-select__control {
    border-radius: 25px;
  }

  :global .language-select__indicator-separator {
    display: none;
  }

  :global .language-select__single-value {
    left: 50%;
    transform: translate(-50%, -50%);
  }

  :global .language-select__indicators {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }

  :global .language-select__indicator {
    color: #FBB228 !important;
    padding: 0 !important;

    svg {
      width: 25px;
      height: 25px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .profile {
    text-align: center;
  }

  .languageSelect, .profileButton {
    display: block;
    margin: 0 auto;
  }
}

@include media-breakpoint-down(xs) {
  .languageSelect, .profileButton {
    width: 90%;
  }
}