@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animate($name, $duration, $infinite : false, $delay : 0s) {
    animation-fill-mode: both;
    animation-duration: $duration;
    animation-name: $name;

    @if ($infinite == true) {
        animation-iteration-count: infinite;
    }

    @if ($delay != 0s or $delay != 0ms) {
        animation-delay: $delay;
    }

    @include keyframes($name) {
        @content
    }
}

@include keyframes(fadeIn) {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@include keyframes(fadeOut) {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}