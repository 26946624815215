// Utilities
@import "styles/utilities/variables";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.empty {
    display: flex;
    align-items: center;
    margin: 40px 0 30px;

    img {
        max-width: 160px;
        display: block;
        margin-left: 8px;
        margin-right: 50px;
    }

    p {
        text-align: center;
        font-weight: 700;
        font-size: 1.3em;
    }

    a {
        cursor: pointer;
        background: #313436;
        line-height: 38px;
        padding: 0 35px;
        border-radius: 30px;
        color: #fff !important;
        display: inline-block;
        transition: all 250ms ease-in-out;

        &:hover {
            opacity: 80%;
        }
    }
}