// Utilities
@import "styles/utilities/variables";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.supportSection {
  position: relative;
  padding: 20px;
  position: relative;
  background: url("./assets/kamat-side.svg"), url("./assets/background.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: top 60px right 0px, top 0px right 0px;
  background-size: 35px auto, 400px 300px;
}

@include media-breakpoint-down(xl) {
  .supportSection {
    border-top-right-radius: 15px;
  }
}

.arrow {
  @include media-breakpoint-down(xl) {
    display: none;
  }
}

.decoration {
  @include media-breakpoint-down(xl) {
    display: flex;
    justify-content: center;
  }
}
