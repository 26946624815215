@import "styles/utilities/variables";
@import "styles/utilities/animations";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.cartSidebar {
    flex: 1 1 0;
    background: #fff;
    max-width: 420px;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
    border-radius: 1.5rem;
    padding: 20px;
    margin-left: 60px;
    position: relative;

    @include media-breakpoint-down($mobile-grid) {
        margin-left: 0;
        margin-bottom: 30px;
        max-width: 100%;
        width: 100%;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    }

    &Toggle {
        background: $primary-color;
        width: 45px;
        height: 45px;
        position: absolute;
        top: 15px;
        right: 15px;
        border-radius: 14px;
        opacity: 80%;
        display: none;

        & > span {
            width: 15px;
            height: 15px;
            border-radius: 0 5px 0 0;
            border-width: 3px 3px 0 0;
            border-color: #fff;
            border-style: solid;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-60%, -50%) rotate(45deg);
            transition: $transition;
        }
    }

    @include media-breakpoint-down($mobile-grid) {
        &Toggle {
            display: block;
        }

        &Opened &Toggle > span {
            transform: translate(-50%, -65%) rotate(135deg);
        }

        &:not(&Opened) {
            border-radius: 12px;
            display: flex;
            align-items: center;

            h3 {
                line-height: 1;
                margin-top: 8px;

                &:after {
                    display: none;
                }

                span {
                    display: inline;
                    background: $black-color;
                    color: #fff;
                    margin-left: 8px;
                    padding: 4px 9px 3px;
                    border-radius: 14px;
                    font-size: 0.9em;
                }
            }

            & > ul {
                display: none;
            }
        }

        &:not(&Opened) &Submit, &:not(&Opened) &Empty {
            display: none;
        }
    }

    h3 {
        text-align: center;
        margin: 0;
        padding: 0;
        position: relative;

        span {
            display: none;
        }

        @include media-breakpoint-down($mobile-grid) {
            text-align: left;
            display: table;
        }

        &:after {
            content: '';
            width: 80px;
            height: 3px;
            background: $primary-color;
            position: absolute;
            bottom: -5px;
            left: 50%;
            transform: translateX(-50%);

            @include media-breakpoint-down($mobile-grid) {
                left: 0;
                transform: none;
            }
        }
    }

    ul {
        list-style: none;
        margin: 40px 0 0;
        padding: 0;
    }

    &Empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 0 10px;

        svg {
            color: $gray-color;
            font-size: 70px;
            margin-bottom: 10px;
            opacity: 50%;
        }

        p {
            margin: 0;
            color: $second-font-color;
        }
    }

    &Item {
        display: flex;
        align-items: center;
        position: relative;

        &:hover &Delete {
            opacity: 1;
        }

        &Image {
            background: #fff;
            width: 60px;
            height: 60px;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.22);
            position: relative;
            border-radius: 15px;
            margin-right: 20px;

            img {
                max-width: 45px;
                max-height: 45px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                position: absolute;
            }
        }

        &Delete {
            background: #fff;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: block;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
            text-align: center;
            line-height: 40px;
            font-size: 18px;
            color: $danger-color !important;
            transition: $transition;
            cursor: pointer;
            opacity: 0;
        }

        &Deleting {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: rgba(255, 255, 255, 0.85);

            p {
                width: 100%;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                text-align: center;
                font-weight: 700;
                color: $danger-color;
            }
        }

        &Content {
            p:first-child {
                margin: 0;
                font-family: Montserrat, sans-serif;
                font-weight: 600;
                font-size: 0.9em;

                & + p {
                    font-size: 0.8em;
                    font-weight: 700;
                    color: $third-font-color;
                    margin: 0;

                    span + span {
                        margin-left: 10px;
                        padding-left: 10px;
                        position: relative;

                        &:before {
                            content: '';
                            width: 6px;
                            height: 6px;
                            background: $seventh-color;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            border-radius: 2px;
                            transform: translate(-50%, -50%);
                        }
                    }
                }
            }
        }

        & + & {
            margin-top: 15px;
            padding-top: 15px;
            position: relative;

            &:before {
                content: '';
                width: 95%;
                height: 1px;
                border-top: 2px dashed rgba(0, 0, 0, 0.15);
                position: absolute;
                top: -1px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    &Submit {
        background: $primary-color;
        color: $black-color;
        width: 100%;
        line-height: 45px;
        display: table;
        border-radius: 14px;
        text-align: center;
        text-transform: uppercase;
        opacity: 90%;
        padding-top: 5px;
        font-weight: 700;
        transition: $transition;
        cursor: pointer;
        margin-top: 40px;

        &Disabled {
            cursor: default;
            opacity: 40%;
            color: $black-color !important;
        }

        svg {
            margin-left: 6px;
            font-size: 20px;
            vertical-align: -4px;
        }

        &:not(&Disabled):hover {
            color: #000;
            transform: scale(1.02);

            svg {
                color: #000;
            }
        }
    }
}