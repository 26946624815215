@import "styles/utilities/variables";
@import "styles/utilities/animations";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.rentProductModal {
    background: transparent;
    position: absolute;
    width: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;

    @include media-breakpoint-down($mobile-grid) {
        position: fixed;
        width: 100%;
        height: 100%;
        background: #dfe4ea;
        padding: 30px 25px;
    }

    &:after {
        content: '';
        width: 100vh;
        height: 100vh;
        max-width: 700px;
        min-width: 600px;
        min-height: 600px;
        background: url("./assets/background.svg") no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-45%, -50%);
        z-index: -1;

        @include media-breakpoint-down($mobile-grid) {
            display: none;
        }
    }

    &Opening {
        transform-origin: center -50%;

        @include animate(rentProductOpening, 250ms) {
            0% {
                transform: scale(0) translate(-50%, -50%);
                opacity: 0;
            }
            30% {
                opacity: 0;
            }
            100% {
                opacity: 1;
                transform: scale(1) translate(-50%, -50%);
            }
        }
    }

    &Closing {
        transform-origin: center -50%;

        @include animate(rentProductClosing, 250ms) {
            0% {
                transform: scale(1) translate(-50%, -50%);
                opacity: 1;
            }
            70% {
                opacity: 0;
            }
            100% {
                transform: scale(0) translate(-50%, -50%);
                opacity: 0;
            }
        }
    }

    h2 {
        font-size: 38px;
        color: #fff;
        text-align: center;
        margin-bottom: 30px;

        @include media-breakpoint-down($mobile-grid) {
            color: $black-color;
        }
    }

    &Product {
        background: #fff;
        border-radius: 12px;
        display: flex;
        font-family: Montserrat, sans-serif;
        align-items: center;
        margin-bottom: 30px;
        padding: 12px 20px;

        p {
            margin: 0;
        }

        img {
            margin-right: 20px;
            max-width: 45px;
            max-height: 45px;
        }
    }

    &Footer {
        margin-top: 60px;
        display: flex;
        align-items: center;

        @include media-breakpoint-down($mobile-grid) {
            flex-direction: column;

            & > * {
                width: 100%;
                text-align: center;
            }

            & > * + * {
                margin-top: 20px;
            }
        }

        &Add, &Close {
            text-transform: uppercase;
            line-height: 42px;
            padding: 0 22px;
            border-radius: 20px;
            font-weight: 700;
            border: none;
            outline: none;
            cursor: pointer;
            transition: $transition;

            @include media-breakpoint-down($mobile-grid) {
                line-height: 50px;
            }

            &:hover {
                transform: scale(1.03);
            }
        }

        &Add {
            background: #FFE636;
            color: #070707 !important;
            padding-top: 2px;

            @include media-breakpoint-down($mobile-grid) {
                background: $primary-color;
            }
        }

        &Close {
            background: $black-color;
            color: #fff !important;
            margin-left: 20px;

            @include media-breakpoint-down($mobile-grid) {
                margin-left: 0;
            }
        }
    }
}