@import "styles/utilities/variables";
@import "styles/utilities/animations";

.rentCard {
    display: flex;
    align-items: center;

    @include media-breakpoint-down($mobile-grid) {
        flex-direction: column;
    }

    & + & {
        border-top: 1px solid #D9D5EC;
        margin-top: 22px;
        padding-top: 22px;
    }

    &Img {
        background: #fff;
        position: relative;
        box-shadow: 0 0 16px -2px rgba(0, 0, 0, 0.27);
        border-radius: 1rem;
        width: 70px;
        height: 70px;
        margin-right: 25px;

        @include media-breakpoint-down($mobile-grid) {
            width: 90%;
            height: auto;
            padding: 20px;
            background: #eee;
            margin: 0 0 20px;

            img {
                max-width: 90%;
                max-height: 130px;
                display: block;
                margin: 0 auto;
            }
        }

        @include media-breakpoint-up($mobile-grid) {
            img {
                max-width: 50px;
                max-height: 50px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    &Content {
        flex: 1 1 0;
    }

    &Tools {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @include media-breakpoint-down($mobile-grid) {
            margin-top: 20px;
        }

        span {
            text-transform: uppercase;
            color: $eighth-color;
            font-weight: 600;
            font-size: 0.8em;
            padding: 4px 12px;
            border-radius: 13px;

            &:before {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                content: '';
                display: inline-block;
                margin-right: 6px;
                background: $eighth-color;
            }

            &:global.green {
                background: #93FF0A;
            }

            &:global.yellow {
                background: #FAFF0A;
            }

            &:global.blue {
                background: #b8e8ed;
            }

            &:global.orange {
                background: #FFB800;
            }
        }

        &Details {
            color: $eighth-color;
            font-weight: 500 !important;
            font-size: 0.9em;
            cursor: pointer;
            margin-top: 5px;

            svg {
                margin-right: 2px;
            }

            &:hover {
                color: $second-font-color;
            }
        }
    }

    h3 {
        font-size: 1em;
        line-height: 1;
        font-weight: 700;
        margin-bottom: 8px;

        @include media-breakpoint-down($mobile-grid) {
            font-size: 1.1em;
            margin-bottom: 10px;
        }
    }

    p {
        margin: 0;
        line-height: 1.3em;
        font-size: 15px;
        color: $second-font-color;

        b {
            font-weight: 500;
            color: $third-color;
        }

        svg {
            color: $primary-color;
            height: 14px;
            margin-right: 3px;
        }

        small {
            color: #6E6893;
            font-family: "Inter", sans-serif;
            font-weight: 500;
        }
    }
}