@import "styles/utilities/variables";

.invoice {
    background: #fff;
    width: 100%;
    height: 100%;
    min-width: 100vw;
    min-height: 100vh;
    font-size: 13px;

    p, h2, h4, h5 {
        margin: 0;
    }

    &Page {
        width: 210mm;
        height: 297mm;
        padding: 10mm 10mm 5mm;
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        flex-direction: column;
        position: relative;

        &Watermark {
            position: absolute;
            top: 4mm;
            right: 10mm;
            font-size: 11px;
        }

        &Header {
            display: flex;
            align-items: flex-start;
            border-bottom: 1px solid $input-border-color;
            padding-bottom: 10mm;
            padding-top: 5mm;

            & > * {
                flex: 1 1 50%;
            }

            &Logo {
                img {
                    flex-shrink: 0;
                    max-width: 200px;
                    width: 100%;
                    height: auto;
                }
            }

            &Company {
                flex: 1 1 0;
                text-align: right;

                h2 {
                    font-size: 25px;
                    line-height: 1;
                    margin-bottom: 8px;
                }

                p {
                    line-height: 1.1em;
                }
            }
        }

        &Information {
            padding: 10mm 0;
            display: flex;
            align-items: flex-start;

            &Client, &Supplier {
                flex: 1 1 0;

                h4 {
                    font-size: 16px;
                    line-height: 1;
                    margin-bottom: 5px;
                }

                p {
                    line-height: 1.2em;

                    b {
                        font-weight: 500;
                    }
                }

                p + p {
                    font-size: 0.9em;
                }
            }

            &Meta {
                display: flex;
                flex-direction: column;

                ul {
                    list-style: none;
                    margin: 0;

                    li {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        p {
                            line-height: 1;
                        }

                        p:first-child {
                            padding-right: 25px;
                            font-weight: 500;
                        }

                        p + p {
                            font-size: 0.9em;
                        }
                    }

                    li + li {
                        margin-top: 7px;
                    }
                }
            }
        }

        &Items {
            table-layout: fixed;
            width: 100%;

            thead {
                background: $seventh-color;
                color: #fff;

                th {
                    padding: 2mm 0;

                    &:first-child {
                        width: 10mm;
                    }

                    &:last-child {
                        width: 24mm;
                        text-align: right;
                    }

                    &:nth-child(3), &:nth-child(4) {
                        width: 10mm;
                        text-align: center;
                    }
                }
            }

            tbody {
                td {
                    &:last-child {
                        text-align: right;
                    }

                    &:nth-child(3), &:nth-child(4) {
                        text-align: center;
                    }
                }
            }

            td, th {
                &:first-child {
                    padding-left: 3mm;
                }
                &:last-child {
                    padding-right: 3mm;
                }
            }

            td {
                padding-top: 2mm;
                padding-bottom: 1mm;

                h4 {
                    font-size: 14px;

                    small {
                        font-size: 11px;
                        font-weight: normal;
                        margin-left: 5px;
                        font-family: "Inter", sans-serif;
                    }
                }
            }

            &Main {
                background: #fef8e9;

                p {
                    font-size: 11px;
                    line-height: 13px;

                    b {
                        font-weight: 500;
                    }
                }
            }
        }

        &Price {
            align-items: stretch;
            justify-content: space-between;
            display: flex;
            list-style: none;
            flex-direction: column;
            max-width: 300px;
            margin: 60px 0 0 0;
            flex: 1 0 0;

            &Info {
                display: flex;
                align-items: flex-end;
                width: 100%;
                justify-content: space-between;

                &Payment {
                    h3 {
                        font-size: 20px;
                        color: #6ab04c;

                        svg {
                            font-size: 22px;
                            margin-right: 4px;
                        }
                    }
                }
            }

            li {
                padding: 1mm 3mm;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &Total {
                background: $seventh-color;
                color: #fff;
                font-weight: 700;
                font-size: 1.1em;
                padding: 2mm 3mm !important;
            }
        }

        &Footer {
            margin-top: auto;
            line-height: 1;
            border-top: 1px solid $input-border-color;
            padding-top: 5mm;
        }
    }
}