@import "styles/utilities/variables";
@import "styles/utilities/animations";

.form {
    display: flex;
    flex-wrap: wrap;
    width: 400px;

    & > p {
        width: 100%;
        font-weight: 600;
        color: $second-font-color;
        font-size: 0.9em;
        margin-bottom: 5px;
    }

    &Ccn, &Cvv, &Cce, &Cowner {
        transition: $transition;
    }

    &Invalid &Ccn, &Invalid &Cvv, &Invalid &Cce, &CownerInvalid {
        border-color: $danger-color !important;
    }

    input {
        box-shadow: unset;
        outline: none;
        padding: 11px 13px;
        flex: 1 1 0;
        min-width: 0;
        background: transparent;

        &::placeholder {
            color: $third-font-color;
        }
    }

    &Ccn {
        flex: 1 0 100%;
        display: flex;
        align-items: center;
        border: 1px solid $input-border-color;
        border-radius: 12px 12px 0 0;
        padding-right: 13px;

        input {
            border: none;
        }

        & > i {
            font-size: 30px;
            color: $third-font-color;
            opacity: 70%;
        }

        & > img {
            width: 37px;
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
        }
    }

    &Cvv, &Cce {
        max-width: 50%;
        flex: 1 0 50%;
    }

    &Cvv {
        display: flex;
        align-items: center;
        border-width: 0 1px 1px 0;
        border-color: $input-border-color;
        border-style: solid;
        border-radius: 0 0 12px 0;
        padding-right: 13px;

        img {
            max-width: 28px;
        }

        input {
            border: none;
            flex: 1 1 0;
            min-width: 0;
        }
    }

    &Cce {
        border-width: 0 1px 1px 1px;
        border-color: $input-border-color;
        border-style: solid;
        border-radius: 0 0 0 12px;
        margin-right: 0 !important;
    }

    &Cco {
        margin-top: 14px;
        flex: 1 1 100%;
    }

    &Error {
        margin-top: 3px;
        margin-bottom: 14px;
        text-align: center;
        color: $danger-color !important;
    }

    &Cowner {
        flex: 1 0 100%;
        display: flex;
        align-items: center;
        border: 1px solid $input-border-color;
        border-radius: 12px;
        margin: 0 !important;
    }

    .eo-input-feedback-error {
        font-weight: 600;
    }
}