// Utilities
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "styles/utilities/variables";
@import "styles/utilities/animations";

.errorText {
    color: $danger;
}

.checkoutModal {
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: 0;
    position: fixed;
    padding: 0;

    &In {
        @include animate(checkout-modal-opening, 300ms) {
            0% {
                transform: translateY(-100%);
                opacity: 0;
            }
            100% {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }

    &Out {
        @include animate(checkout-modal-closing, 300ms) {
            0% {
                transform: translateY(0);
                opacity: 1;
            }
            70% {
                opacity: 0;
            }
            100% {
                transform: translateY(-100%);
                opacity: 0;
            }
        }
    }

    &Wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        overflow-y: auto;
        padding: 120px 0 50px 0;

        & > h1 {
            font-size: 38px;
            margin-bottom: 100px;

            small {
                font-size: 28px;
                color: $second-font-color;
            }
        }
    }

    &Body {
        max-width: 700px;

        &Payment {
            display: flex;
            align-items: flex-start;
            max-width: 1200px;

            @include animate(checkout-payment-in, 300ms) {
                0% {
                    transform: translateY(-30px);
                    opacity: 0;
                }
                100% {
                    transform: translateY(0);
                    opacity: 1;
                }
            }

            &Secure {
                text-align: center;

                p {
                    margin: 0;
                }

                &Title {
                    font-size: 1.2em;
                    font-weight: 500;
                    margin: 30px 0 20px !important;
                }
            }

            h2 {
                font-size: 23px;
                margin-bottom: 30px;
            }

            &Form {
                margin-right: 150px;

                &Alert, &Error {
                    display: flex;
                    align-items: center;
                    border-radius: 12px;
                    padding: 15px 20px;
                    margin-bottom: 20px;

                    p {
                        margin: 0;
                    }

                    svg {
                        font-size: 30px;
                        margin-right: 20px;
                    }
                }

                &Alert {
                    color: #308800;
                    background: #ebf9e1;
                }

                &Error {
                    background: #f9d5cf;
                    color: #a03828;
                }

                &Btn {
                    margin-top: 40px;
                    width: 400px;
                    min-width: 400px;

                    & + p {
                        font-weight: 500;
                        font-size: 0.9em;
                        margin-top: 5px;
                        color: $third-font-color;
                    }
                }
            }

            &Resume {
                min-width: 400px;

                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0 0 80px 0;
                }

                &Price {
                    width: 310px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0 0 0 auto;
                    position: relative;

                    &:after {
                        border-top: 1px dashed $input-border-color;
                        z-index: -1;
                        content: '';
                        position: absolute;
                        top: 50%;
                        width: 95%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }

                    p {
                        margin: 0;
                        display: table;
                        background: #fff;

                        &:first-child {
                            padding-right: 8px;
                        }
                        &:last-child {
                            padding-left: 8px;
                        }
                    }

                    &Total {
                        font-weight: 500;
                        font-size: 1.3em;
                    }
                }

                &Price + &Price {
                    margin-top: 5px;
                }
            }

            &Item {
                display: flex;
                align-items: flex-start;

                &Pic {
                    width: 80px;
                    height: 80px;
                    border: 1px solid $border-color;
                    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
                    position: relative;
                    border-radius: 18px;
                    flex-shrink: 0;
                    margin-right: 20px;

                    img {
                        max-width: 60px;
                        max-height: 60px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                &Info {
                    h5 {
                        font-size: 17px;
                    }

                    p {
                        line-height: 1.1;
                        margin: 0;
                        font-size: 0.8em;

                        b {
                            font-weight: 600;
                        }

                        svg {
                            color: $seventh-color;
                            margin-right: 3px;
                            font-size: 1.3em;
                            vertical-align: -4px;
                            width: 20px;
                        }
                    }

                    p + p {
                        margin-top: 4px;
                    }
                }
            }

            &Item + &Item {
                margin-top: 30px;
            }
        }
    }

    &Footer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;

        & > a {
            font-weight: 500;
            margin-right: 30px;
            display: table;
        }
    }

    &Resume {
        p {
            margin: 0;

            span {
                color: $third-font-color;
                font-weight: 500;
            }
        }

        h2 {
            margin-bottom: 30px;

            svg {
                font-size: 1.2em;
                margin-right: 10px;
                vertical-align: -0.2em;
            }
        }

        button {
            margin-top: 45px;
            width: 300px;
        }

        &Success {
            h2 {
                svg {
                    color: #6ab04c;
                }
            }
        }

        &Refused {
            h2 {
                svg {
                    color: #e55039;
                }
            }
        }
    }
}

.payments {
    display: flex;
    align-items: flex-start;

    h4 {
        font-weight: 700;
        font-family: Panton, sans-serif;
        font-size: 25px;

        span {
            color: $primary-color;
        }
    }

    & > div {
        position: relative;
    }

    &Img {
        height: 110px;
        position: absolute;
        top: 25px;
        transform: rotate(-35deg);
        right: 80px;
    }

    &List {
        flex: 1 1 55%;
        display: flex;
        flex-direction: column;
        min-height: 130px;
        justify-content: flex-end;
    }

    &Method {
        border: 1px solid rgba(209, 209, 209, 1);
        border-radius: 12px;
        padding: 0 22px;
        position: relative;

        input[type=radio] {
            display: none;

            &:checked + label:before {
                border-color: $primary-color;
                background: $primary-color;
            }
        }

        &Soon {
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(255, 255, 255, 0.75);
            width: 100%;
            height: 100%;
            border-radius: 12px;
            text-align: center;

            p {
                text-align: center;
                top: 50%;
                left: 50%;
                color: rgba(0, 0, 0, 0.8);
                transform: translate(-50%, -50%);
                position: absolute;
                font-weight: 600;
                font-size: 20px;
                text-shadow: 1px 0 #fff, 2px 0 #fff, 3px 0 #fff, 0 -1px #fff, 0 -2px #fff, 0 -3px #fff, 0 1px #fff, 0 2px #fff, 0 3px #fff, 0 -1px #fff, 0 -2px #fff, 0 -3px #fff;
            }
        }

        img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            max-height: 40px;
            max-width: 40px;
            right: 20px;
        }

        label {
            margin: 0;
            padding: 20px 0 20px 37px;
            font-family: "Poppins", sans-serif;
            font-weight: 600;
            font-size: 16px;
            line-height: 1;
            position: relative;
            cursor: pointer;

            &:before {
                width: 22px;
                height: 22px;
                border-radius: 11px;
                border: 2px solid rgba(209, 209, 209, 1);
                background: #fff;
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                transition: all 250ms ease-in-out;
            }

            &:after {
                width: 10px;
                height: 10px;
                border-radius: 5px;
                background: #fff;
                content: '';
                position: absolute;
                left: 6px;
                top: 50%;
                transform: translateY(-50%);
            }

            small {
                display: block;
                font-weight: 500;
                color: #95a5a6;
                line-height: 1;
                font-size: 14px !important;
            }
        }
    }

    &Method + &Method {
        margin-top: 12px;
    }
}

.conditions {
    margin-top: 40px !important;

    h5 {
        font-family: Poppins, sans-serif;
        font-weight: 600;
        line-height: 30px;
        font-size: 22px;

        & + p {
            font-family: Poppins, sans-serif;
            font-weight: 500;
            color: rgba(169, 169, 169, 1);
        }
    }

    &Line {
        border: 1px solid rgba(209, 209, 209, 1);
        border-radius: 12px;
        align-items: center;
        padding: 10px 16px;
        display: table;

        input[type=checkbox] {
            display: none;

            &:checked + label:before {
                background: $primary-color;
                border-color: $primary-color;
            }
        }

        label {
            position: relative;
            padding: 0 0 0 35px;
            line-height: 1;
            cursor: pointer;

            &:before {
                width: 22px;
                height: 22px;
                display: block;
                border-radius: 4px;
                border: 1.5px solid #D1D1D1;
                position: absolute;
                content: '';
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                background: #fff;
                transition: all 100ms ease-in-out;
            }

            &:after {
                content: '✓';
                width: 24px;
                text-align: center;
                line-height: 22px;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                position: absolute;
                font-size: 23px;
                color: #fff;
            }
        }
    }

    &Line + &Line {
        margin-top: 10px;
    }
}