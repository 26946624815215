@import "styles/utilities/variables";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.faq {
    max-width: 900px;

    h1 {
        text-align: center;
        font-size: 30px;
        margin-bottom: 40px;
    }

    &Body {
        padding: 20px;
        max-height: 500px;
        overflow: auto;
    }

    &Container {
        & + & {
            margin-top: 50px;
        }
    }

    &Question {
        font-size: 1.1em;
        font-weight: 600;
        position: relative;
        padding-left: 20px;

        &:before {
            content: '';
            width: 10px;
            height: 10px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            background: $seventh-color;
            border-radius: 5px;
        }
    }

    &Answer {

    }
}