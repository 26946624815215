.loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &Spinner {
        $size: 50px;
        $border: 4px;

        position: relative;
        border-top: $border solid rgba(255, 255, 255, 0.2);
        border-right: $border solid rgba(255, 255, 255, 0.2);
        border-bottom: $border solid rgba(255, 255, 255, 0.2);
        border-left: $border solid #ffffff;
        transform: translateZ(0);
        -webkit-animation: load2 1.1s infinite ease-in-out;
        animation: load2 1.1s infinite ease-in-out;
        margin: 0 auto;
        display: block;

        &, &:after {
            border-radius: 50%;
            width: $size;
            height: $size;
        }
    }

    p {
        color: #fff;
        font-weight: 500;
        font-size: 20px;
        margin-top: 12px;
    }
}

@keyframes load2 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}