// Utilities
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "styles/utilities/variables";

.productCard {
    position: relative;
    background-color: $fifth-color;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    cursor: pointer;

    &Title {
        text-align: center;
        font-family: Montserrat, sans-serif;
        color: #3B3B3B;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        line-height: 1em;
    }

    &Pic {
        margin-top: 30px;
        margin-bottom: 30px;
        width: 30%;
    }

    &Icons {
        position: absolute;
        top: 20px;
        left: 20px;
        width: 100%;

        & > img {
            width: 100%;
            max-width: 60px;
        }

        img + img {
            margin-left: 10px;
        }
    }

    &Break {
        margin: 20px 0 0;
        width: 72px;
        height: 2px;
        background: #ffb160;
    }

    &Add {
        margin-top: 15px;
        background: #FFD12F;
        padding: 5px 30px 5px 14px;
        border-radius: 22px;
        position: relative;
        cursor: pointer;
        transition: $transition;
        font-size: 0.9em;

        &:hover {
            background: $primary-color;
            color: #000;
            transform: scale(1.02);
        }

        span {
            font-weight: bold;
            font-size: 25px;
            position: absolute;
            top: 50%;
            display: block;
            line-height: 1;
            transform: translateY(-50%);
            right: 11px;
        }
    }
}