// Utilities
@import "styles/utilities/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.nav {
    width: 24%;

    @include media-breakpoint-down($mobile-grid) {
        display: none;
    }
}

.body {
    margin-left: 30px;
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;

    @include media-breakpoint-down($mobile-grid) {
        margin-top: -40px;
        width: 100%;
        margin-left: 0;
    }
}

.wrapper {
    display: flex;
}

.globalContainer {
    padding: 40px 30px;
}

@include media-breakpoint-up(md) {
    .globalContainer {
        background: url("/assets/kamat-side.svg") no-repeat top 60px right 0, url("/assets/background.svg") no-repeat top 0 right 0;
        background-size: 35px auto, 400px 300px;
        height: 100%;
        display: flex;
        flex-direction: column;

        & > :global(.row) {
            flex-grow: 1;
        }
    }
}

@include media-breakpoint-up(xl) {
    .globalContainer {
        padding-right: 55px !important;
    }
}

@include media-breakpoint-down(xl) {
    .globalContainer {
        border-top-right-radius: 15px;
    }
}