// Utilities
@import "styles/utilities/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.menuContainer,
.mainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    flex: 1 1 100%;
}

.offers {
    &Title {
        font-size: 32px;
        line-height: 1;

        @include media-breakpoint-down($mobile-grid) {
            font-size: 22px;
        }
    }

    &Body {
        flex: 1 1 60%;
        max-width: 60%;

        @include media-breakpoint-down($mobile-grid) {
            max-width: unset;
            width: 100%;
            padding-top: 30px;
        }
    }

    &Sidebar {
        padding-top: 100px;
        flex: 1 1 0;

        @include media-breakpoint-down($mobile-grid) {
            padding-top: 0;
        }
    }

    &Helper {
        position: relative;
        margin-top: 100px;

        @include media-breakpoint-down($mobile-grid) {
            display: none;
        }

        img {
            margin-left: 90px;
            transform: scaleX(-1) scaleY(-1);
        }

        p {
            text-align: center;
        }
    }

    &SubTitle {
        line-height: 1;
        font-size: 22px;
        margin-top: 5px;
        margin-left: 25px;
        margin-bottom: 50px;
        font-weight: 400;
        color: #484e57;

        @include media-breakpoint-down($mobile-grid) {
            margin: 0 0 20px 0;
            font-size: 20px;
        }

        span {
            font-weight: 700;
            color: $seventh-color;
        }
    }

    &Img {
        position: absolute;
        left: 55%;
        transform: translateX(-50%);
        width: 280px;
        top: 20px;
    }

    &Container {
        padding: 30px 40px;
        display: flex;
        align-items: flex-start;

        @include media-breakpoint-down($mobile-grid) {
            flex-direction: column;
            padding: 30px 20px;
        }
    }

    &NavigationItem {
        display: flex;
        align-items: center;
        padding-top: 18px !important;
        padding-bottom: 16px !important;

        p {
            line-height: 1;
            padding: 0;
            margin: 0;
        }

        svg {
            font-size: 22px;
            margin-right: 12px;
        }
    }

    &Stats {
        display: flex;
        min-height: 110px;
        align-items: center;

        &Box {
            background-color: #ffffff;
            border-radius: 11px;
            box-shadow: 0 7px 23px rgba(0, 0, 0, 0.15);
            text-align: center;
            position: relative;
            padding: 20px 25px 20px 90px;
            margin-left: 30px;

            & > img {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 25px;
                width: 40px;
            }
        }
    }
}

.emptyContent {
    img {
        width: 150px;
        margin: 60px auto 20px;
        display: block;
    }

    p {
        text-align: center;
    }
}

@include media-breakpoint-up(lg) {
    .offersContainer {
        position: relative;
        height: 100%;
        flex: 1 1 0;

        &:before {
            position: absolute;
            content: '';
            height: 400px;
            width: 300px;
            top: 0;
            right: 0;
            background: url("/assets/kamat-side.svg") no-repeat top 60px right 0, url("/assets/background.svg") no-repeat top 0 right 0;
            background-size: 35px auto, 400px 300px;
            z-index: 0;
            border-top-right-radius: 30px;
        }
    }
}

@include media-breakpoint-down(xl) {
    .offers {
        &Container:before {
            border-top-right-radius: 15px;
        }

        &Title {
            top: -5px;
        }
    }
}

@include media-breakpoint-down(lg) {
    .arrowLogo {
        display: none;
    }

    .offersSearch {
        padding: 20px;
    }
}

@include media-breakpoint-up(sm) {
    padding-right: 55px !important;
}

@include media-breakpoint-down(xl) {
    .offersContainer {
        margin-top: -30px;

        &:before {
            top: 30px;
            border-top-right-radius: 15px;
        }
    }

    .offers {
        &Img {
            display: none;
        }

        &Stats {
            padding-top: 25px;
        }
    }
}

@include media-breakpoint-down(md) {
    .offersContainer {
        margin-top: -30px;

        &:before {
            top: 30px;
            border-top-right-radius: 15px;
        }
    }

    .offers {
        &Stats {
            flex-direction: column;

            &Box {
                width: 80%;
                margin-left: 0 !important;
            }

            &Box + &Box {
                margin-top: 15px;
            }
        }
    }
}