@import "styles/utilities/variables";

.siteForm {
    &Row {
        display: flex;
        align-items: center;

        & > * {
            flex: 1 1 0;
            min-width: 0;
            max-width: 48%;
        }
    }
}