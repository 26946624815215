@import "styles/utilities/variables";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.companyInformation {
    margin-top: 25px;
    z-index: 1;
    position: relative;

    &Title {
        text-transform: uppercase;
        font-family: "Panton", sans-serif;
        font-size: 24px;
        margin-bottom: 30px;
    }

    & > p {
        line-height: 1;
        margin-bottom: 14px;

        svg {
            color: #f39c12;
            position: absolute;
            transform: translateX(-150%);
        }

        b {
            font-weight: 500;
        }

        span {
            color: $third-font-color;
        }
    }

    &WaitingAlert {
        background: #d8f3f5;
        border-radius: 1.5rem;
        padding: 20px 30px;
        margin-bottom: 30px;

        h2 {
            font-size: 28px;

            & + p {
                font-weight: 500;
            }
        }

        p {
            margin: 0;
        }

        p + p {
            margin-top: 10px;
        }
    }

    &CompletenessAlert {
        $size: 85px;
        position: relative;
        padding-right: ($size / 2) + 10px;
        margin-bottom: 30px;

        &Body {
            background: #ffe5c8;
            color: #90581a;
            border-radius: 1.5rem;
            padding: 20px (($size / 2) + 10px) 20px 25px;
        }

        h5 {
            color: #e67e22;
            margin-bottom: 15px;

            svg {
                font-size: 1.2em;
                vertical-align: -4px;
                margin-right: 5px;
                opacity: 60%;
            }
        }

        p {
            margin: 0;
            line-height: 1.2em;
        }

        &Percent {
            background: #e67e22;
            color: #fff;
            width: $size;
            height: $size;
            border-radius: 50%;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            box-shadow: 0 0 0 6px #fff;

            p {
                font-weight: 700;
                font-size: 30px;
                font-family: "Montserrat", sans-serif;
                margin: 0;
            }

            span {
                font-size: 0.9em;
                font-weight: 500;
                margin-top: -8px;
            }
        }
    }
}