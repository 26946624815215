@import "styles/utilities/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.linkedProductSelect {
    flex: 1 1 0;

    &Container {
        display: flex;
        align-self: stretch;
    }

    &Prefix {
        width: 35px;
        background: #fff;
        border-bottom: 2px solid $primary-color;
        position: relative;

        img {
            width: 19px;
            position: absolute;
            top: 50%;
            left: 13px;
            transform: translateY(-50%);
        }
    }

    :global(.linkedProduct) {
        &__control {
            border-width: 0 0 2px 0 !important;
            border-radius: 0;
            border-color: $primary-color;

            &--is-focused {
                border-color: $fourth-color !important;
                outline: none !important;
                box-shadow: none !important;
            }
        }

        &__indicator {
            color: $primary-color;

            svg {
                width: 22px !important;
                height: 22px !important;
            }

            &-separator {
                display: none;
            }
        }
    }
}