.btn-blue {
  background: #0078AA;
  border: none;
  outline: none;
  color: #fff;
  line-height: 36px;
  font-family: "Panton";
  font-weight: 700;
  text-align: center;
  width: 100px;
  padding: 0;
  border-radius: 20px;
  margin: 30px auto 0;
  display: block;
  transition: all 150ms ease-in-out;

  &[disabled] {
    opacity: 80%;
    width: 135px
  }
}