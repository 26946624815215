@import "styles/utilities/variables";

.siteFormModal {
    width: 550px;

    &Title {
        font-family: Inter, sans-serif;
        text-transform: uppercase;
        text-align: center;
        font-size: 24px;
        margin-bottom: 30px;
    }

    &Type {
        display: flex;
        align-items: stretch;
        justify-content: center;
        margin: 0 auto 40px;

        &Label {
            margin-bottom: 5px;
            font-weight: 500;
            font-family: Inter, sans-serif;
            font-size: 0.9em;
            text-align: center;
        }

        a {
            padding: 10px 20px;
            transition: $transition;
            border: 2px solid $primary-color;

            &:first-child {
                border-radius: 16px 0 0 16px;
                border-width: 2px 0 2px 2px;
            }

            &:last-child {
                border-radius: 0 16px 16px 0;
                border-width: 2px 2px 2px 0;
            }
        }

        &Active {
            background: $primary-color;
            color: inherit !important;
            cursor: default;
        }

        & > a:not(&Active) {
            cursor: pointer;
            color: inherit !important;

            &:hover {
                background: #fff6e8;
            }
        }
    }
}