@import "styles/utilities/variables";
@import "styles/utilities/animations";

.kmPagination {
    $pagination-btn-size: 42px;
    $pagination-border-color: $second-font-color;
    $pagination-active-color: red;
    
    margin: 15px 0;
    display: flex;

    button {
        font-family: inherit;
    }

    &Btn {
        box-sizing: border-box;
        padding: 0;
        font-family: "Inter", sans-serif;
        font-weight: 500;
        font-size: 15px;
        width: $pagination-btn-size;
        line-height: $pagination-btn-size - 4px;
        border-radius: 8px;
        border: 2px solid $pagination-border-color;
        background: #fff;
        text-align: center;
        cursor: pointer;
        transition: $transition;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-weight: 500;

        &+& {
            margin-left: 5px;
        }

        &Active {
            border-color: $seventh-color;
            background: $seventh-color;
            color: #fff;
            cursor: default !important;
        }

        &:not([disabled]):not(&Active):hover {
            border-color: $seventh-color;
            color: $seventh-color;
        }

        &AbsoluteSeparator {
            position: relative;
            width: $pagination-btn-size;
            height: $pagination-btn-size;

            &:before {
                content: '...';
                position: absolute;
                font-size: 39px;
                top: 8px;
                letter-spacing: -1px;
                opacity: 50%;
                color: #000 !important;
                cursor: default !important;
                user-select: none;
                line-height: 4px;
                text-align: center;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    &Locked &Btn, &Btn[disabled] {
        background: rgba(0,0,0,0.02);
        cursor: default;

        &:not(.kmPaginationBtnActive) {
            color: rgba(0, 0, 0, 0.15);
        }
    }

    &Previous, &Next {
        position: relative;
    }

    &Previous, &Next, &First, &Last {
        font-size: 22px;
        height: $pagination-btn-size;

        & > * {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &:not([disabled]) {
            color: rgba(0,0,0,0.45);
        }
    }
}