.km-input {
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    position: relative;

    $border-radius: 8px;
    $line-height: 44px;
    $padding-x: 14px;

    & > input, .rc-picker-input input, &-uploader, & > select, & > textarea {
        line-height: $line-height;
        padding: 0 $padding-x;
        border-radius: $border-radius;
        border: 1px solid $input-border-color;
        outline: none !important;
        transition: $transition;
        flex: 1 1 0;
        min-width: 0;
        position: relative;
        z-index: 1;
    }

    & > textarea {
        line-height: 1;
        padding: 18px 14px;
    }

    & > select {
        background: #fff;
        height: 44px;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background: url("data:image/svg+xml,<svg height='15px' width='15px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
        background-position: calc(100% - 0.75rem) center !important;
    }

    & > input:focus, & > select:focus, & > textarea:focus {
        border-color: $seventh-color !important;
        box-shadow: 0 0 0 1px $seventh-color !important;

        & + .km-input-suffix {
            border-color: $seventh-color;
            box-shadow: 1px 0 $seventh-color, 0 -1px $seventh-color, 0 1px $seventh-color;
        }
    }

    .rc-picker {
        border: none !important;
        flex: 1 1 0;

        &-focused {
            input {
                border-color: $seventh-color !important;
                box-shadow: 0 0 0 1px $seventh-color !important;
            }
        }
    }

    &-checkbox {
        input {
            visibility: hidden;
            position: absolute;
        }

        label {
            position: relative;
            padding-left: 35px;
            cursor: pointer;
            user-select: none;

            &:before {
                content: '';
                width: 25px;
                height: 25px;
                border: 1px solid $input-border-color;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                background: #fff;
                border-radius: 6px;
                transition: $transition;
            }

            &:after {
                content: '✔';
                position: absolute;
                width: 25px;
                line-height: 25px;
                text-align: center;
                font-size: 22px;
                color: $seventh-color;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                opacity: 0;
                transition: $transition;
            }
        }

        input:checked + label {
            &:before {
                border-color: $seventh-color;
            }

            &:after {
                opacity: 1;
            }
        }
    }

    &-switch {
        height: 30px;
        display: flex;
        align-items: center;

        input {
            visibility: hidden;
            position: absolute;
        }

        label {
            position: relative;
            padding-left: 65px;
            cursor: pointer;
            user-select: none;
            line-height: 1;

            &:before {
                content: '';
                width: 53px;
                height: 30px;
                border: 1px solid $input-border-color;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                background: #fff;
                border-radius: 15px;
                transition: $transition;
            }

            &:after {
                content: '';
                position: absolute;
                width: 25px;
                height: 25px;
                border-radius: 12px;
                left: 4px;
                top: 50%;
                transform: translateY(-50%);
                transition: $transition;
                background: #ced6e0;
            }
        }

        input:checked + label {
            &:before {
                background: #2F3132;
                border-color: #2F3132;
            }

            &:after {
                background: #fff;
                left: 25px;
            }
        }
    }

    &.suffixed {
        & > input, .rc-picker-input input, &-uploader, & > select, & > textarea {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right-width: 0;
        }
    }

    &-suffix {
        border: 1px solid $input-border-color;
        border-left-width: 0;
        padding-right: $padding-x;
        border-radius: 0 $border-radius $border-radius 0;
        line-height: $line-height + 2;
        z-index: 2;
        background: #fff;
        transition: $transition;
    }

    &.titled > input, &.titled .rc-picker-input input, &.titled > select {
        padding-top: 5px;
    }

    &.titled > textarea {
        padding-top: 20px;
    }

    &.titled > select {
        height: 51px;
    }

    &.titled > label:not([class]), .title {
        position: absolute;
        font-size: 0.85em;
        font-weight: 500;
        font-family: Inter, sans-serif;
        z-index: 10;
        top: 0;
        left: 11px;
        transform: translateY(-50%);
        padding: 0 5px;

        &:before {
            width: 100%;
            background: #fff;
            height: 4px;
            top: 10px;
            left: 0;
            content: '';
            position: absolute;
            z-index: -1;
        }
    }

    &.titled &-select {
        &__control {
            padding-top: 5px;
        }
    }

    &-select {
        flex: 1 1 0;
        position: relative;

        &__control {
            border-radius: 8px !important;
            border-color: #becbda !important;
            min-height: 46px !important;

            &--is-focused {
                border-color: $seventh-color !important;
                box-shadow: 0 0 0 1px $seventh-color !important;
            }
        }

        &__indicator {
            padding: 4px 8px;

            svg {
                width: 28px;
                height: 28px;
            }

            &-separator {
                display: none;
            }
        }

        &__menu {
            z-index: 99 !important;
        }

        &__value-container {
            padding: 0 12px !important;
        }
    }

    &:focus {
        border-color: $seventh-color;
        box-shadow: 0 0 0 1px $seventh-color;
    }

    input.is-invalid, select.is-invalid, textarea.is-invalid, &-select.is-invalid &-select__control, input[type=checkbox].is-invalid + label:before {
        border-color: $danger-color !important;
        box-shadow: 0 0 0 1px $danger-color;
    }

    .is-invalid + .km-input-suffix {
        border-color: $danger-color !important;
        box-shadow: 1px 0 $danger-color, 0 -1px $danger-color, 0 1px $danger-color;
    }

    input[type=checkbox].is-invalid + label {
        color: $danger-color;
    }

    &.required {
        label:after {
            content: "*";
            margin-left: 2px;
            font-size: 1.3em;
            color: #e69113;
        }
    }

    .invalid-feedback {
        font-size: 0.8em;
        font-weight: bold;
        margin-top: 2px;
        margin-left: 4px;
    }

    &-checkbox .invalid-feedback {
        margin-left: 35px;
    }
}

.required-legend {
    font-weight: 500;
    font-family: Inter, sans-serif;
    font-size: 0.9em;
    color: $second-font-color;
    line-height: 1;
    margin: 0;
    position: relative;
    padding-left: 15px;

    &:before {
        content: '*';
        color: #e69113;
        font-size: 1.5em;
        font-weight: 700;
        position: absolute;
        left: 0;
        top: 0;
    }
}