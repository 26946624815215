.tox-statusbar__path {
    display: none !important;
}

.tox-statusbar__branding {
    display: none !important;
}

.tox.tox-tinymce {
    border: 1px solid $input-border-color;

    .tox-editor-header {
        border-bottom: 1px solid $input-border-color !important;
        box-shadow: none !important;
    }
}