// Utilities
@import "styles/utilities/variables";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.siteCard {
    background-color: $fifth-color;
    box-shadow: inset 0 -1px 0 #CBD5E0;
    font-size: 14px;
    padding: 25px 0 !important;
    width: 100%;
    display: flex;
    align-items: center;
    font-family: Inter, sans-serif;
    font-size: 14px;

    @include media-breakpoint-down($mobile-grid) {
        flex-direction: column;
        width: 100%;
    }

    h5 {
        font-size: 18px;
        line-height: 1;
        margin: 0;

        @include media-breakpoint-down($mobile-grid) {
            margin-bottom: 14px;
        }
    }

    & > div > p {
        margin: 0;
        line-height: 24px;

        img {
            width: 100%;
            height: 100%;
            max-height: 22px;
            max-width: 22px;
            vertical-align: -5px;
            margin-right: 5px;
        }
    }

    & > div > p + p {
        margin-top: 4px;
    }

    &Name {
        flex: 1 1 15%;

        @include media-breakpoint-down($mobile-grid) {
            width: 100%;
        }
    }

    &Address, &Info {
        flex: 1 1 22%;

        @include media-breakpoint-down($mobile-grid) {
            width: 100%;
        }
    }

    @include media-breakpoint-down($mobile-grid) {
        &Info {
            margin-top: 4px;
        }
    }

    &Tools {
        align-self: center;

        @include media-breakpoint-down($mobile-grid) {
            margin-top: 14px;
            align-self: flex-start;
        }

        & > * + * {
            margin-left: 10px;
        }
    }
}