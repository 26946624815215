@import "styles/utilities/variables";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.promotion {
  font-weight: 700;
  text-align: center;

  @include media-breakpoint-up(xl) {
    text-align: start;
  }
}

.emphasis {
  color: $primary-color;
}
