@import "styles/utilities/variables";
@import "styles/utilities/animations";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.menuContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @include media-breakpoint-down(xl) {
    display: none;
  }
}

ul.subMenu {
  display: flex;
  align-content: center;
  margin: 0;
  padding: 0 0 35px 0;
  list-style: none;
  width: 100%;
  max-width: 100%;
  justify-content: flex-start;
  overflow-x: auto;

  li {
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    font-family: "Poppins";
    font-weight: 500;
    flex-shrink: 0;
    transition: color 250ms ease-in-out;

    &.active {
      color: $fourth-color;
      position: relative;

      &:after {
        width: 34px;
        height: 26px;
        background: transparent;
        content: '';
        position: absolute;
        border-color: $primary-color transparent transparent transparent;
        border-style: solid;
        border-width: 24px 17px 0 17px;
        bottom: -32px;
        left: 50%;
        transform: translate(-50%, 0);
        opacity: 1;

        @include animate(activeArrowIn, 250ms) {
          from {
            transform: translate(-50%, -8px);
            opacity: 0;
          }
          to {
            opacity: 1;
            transform: translate(-50%, 0);
          }
        }
      }
    }
  }

  li + li {
    margin-left: 20px;
  }
}