@import "styles/utilities/variables";

.searchClear svg {
    font-size: 25px;
    color: $danger-color;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
}

@include media-breakpoint-down($mobile-grid) {
    .search {
        width: 100%;
        margin-top: 15px;
        margin-bottom: 20px;
    }
}

.empty {
    width: 100%;
    display: flex;
    padding: 20px 10px;
    align-items: center;

    @include media-breakpoint-down($mobile-grid) {
        flex-direction: column;
    }

    img {
        width: 80px;
    }

    div {
        margin-left: 30px;

        @include media-breakpoint-down($mobile-grid) {
            margin-left: unset;
            margin-top: 20px;
        }
    }

    p {
        color: $second-font-color;
        margin-bottom: 0;
    }

    a {
        background: $primary-color;
        border-radius: 1rem;
        padding: 5px 12px;
        font-size: 0.9em;
        margin-top: 4px;
        display: table;

        svg {
            color: $second-font-color;
            margin-left: 3px;
        }

        &:hover {
            color: $font-color;
        }
    }
}