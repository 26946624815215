// Utilities
@import "styles/utilities/variables";
@import "styles/utilities/animations";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.productManager {
    position: relative;

    &Header {
        display: flex;
        align-items: flex-end;
        justify-content: space-around;
        margin-bottom: 50px;

        @include media-breakpoint-down($mobile-grid) {
            flex-direction: column;
            margin-bottom: 40px;
        }

        &Img {
            img {
                margin-top: -70px;

                @include media-breakpoint-down($mobile-grid) {
                    display: none;
                }
            }
        }

        &Img + &Box {
            margin-left: 16%;

            @include media-breakpoint-down($mobile-grid) {
                margin-left: 0;
            }
        }

        &Box {
            background-color: #ffffff;
            border-radius: 11px;
            box-shadow: 0 14px 30px rgba(0, 0, 0, 0.12);
            text-align: center;
            display: flex;
            align-items: center;
            padding: 25px 35px;

            @include media-breakpoint-down($mobile-grid) {
                width: 100%;
                padding: 0;
                box-shadow: none;
                border-radius: 0;

                & + & {
                    margin-top: 20px;
                    padding-top: 20px;
                    border-top: 1px solid $input-border-color;
                }

                & > div {
                    flex: 1 1 0;
                }
            }

            img {
                margin-right: 25px;
            }

            & > div > * {
                margin: 0;
            }
        }
    }

    &ToolBar {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @include media-breakpoint-down($mobile-grid) {
            flex-direction: column;
            margin-bottom: 10px;
            align-items: flex-start;

            :global .km-search {
                width: 100%;
                margin-bottom: 10px;
            }
        }

        &Btn {
            background: none;
            border: none;
            box-shadow: none;
            font-weight: 700;
            min-width: 200px;

            @include media-breakpoint-down($mobile-grid) {
                min-width: unset;
            }

            img {
                vertical-align: -8px;

                @include media-breakpoint-down($mobile-grid) {
                    margin: 0 8px 0 0 !important;
                }
            }
        }
    }

    &Body {
        padding: 30px 0;
    }

    &Empty {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;

        h4 {
            margin: 0;
        }

        & > svg {
            font-size: 70px;
            color: rgba(0, 0, 0, 0.16);
            margin-right: 30px;
        }

        a {
            color: $second-font-color !important;
            cursor: pointer;

            & > svg {
                color: $seventh-color;
                margin-right: 4px;
            }
        }
    }
}

.productLogo {
    position: absolute;
    top: -70px;
    display: none;

    @include animate(logoIn, 450ms) {
        from {
            transform: translateX(-25px);
            opacity: 0;
        }
        to {
            transform: translateX(0);
            opacity: 1;
        }
    }

    @include media-breakpoint-up(xl) {
        display: block;
    }
}


.arrowLogo {
    display: none;
    @include media-breakpoint-up(xxl) {
        display: block;
    }
}

.searchBoxInput {
    color: #6e6893;
}




.finderContainer {
    color: $fifth-color;
}

.listContainer {
    overflow-x: hidden;
}

/* scrollbar width */
.listContainer::-webkit-scrollbar {
    width: 10px;
}

/* scrollbar track */
.listContainer::-webkit-scrollbar-track {
    background: #eee;
}

/* scrollbar handle */
.listContainer::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: $primary-color;
    background-image: linear-gradient(
                    to top,
                    $primary-color 0%,
                    $fifth-color 100%
    );
}