// Utilities
@import "styles/utilities/variables";
@import "styles/utilities/animations";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";


.catalog {
    padding: 30px 45px;

    @include media-breakpoint-down($mobile-grid) {
        padding: 30px 25px;
    }

    &Header {
        margin-bottom: 60px;

        @include media-breakpoint-down($mobile-grid) {
            margin-bottom: 30px;
        }

        h1 {
            font-size: 32px;
            line-height: 1;
            margin: 0;
        }

        h2 {
            line-height: 1;
            font-size: 22px;
            margin-top: 5px;
            margin-left: 25px;
            font-weight: 400;
            color: $second-font-color;

            @include media-breakpoint-down($mobile-grid) {
                margin-left: 0;
                line-height: 26px;
                text-align: left;
            }

            @include animate(titleIn, 250ms) {
                from {
                    opacity: 0;
                    transform: translateY(-20px);
                }
                to {
                    opacity: 1;
                    transform: translateY(0);
                }
            }

            span {
                font-weight: 700;
                color: $seventh-color;
            }
        }
    }

    &Wrapper {
        display: flex;
        align-items: flex-start;

        @include media-breakpoint-down($mobile-grid) {
            flex-direction: column;
        }
    }

    &Body {
        flex: 1 1 0;

        @include media-breakpoint-down($mobile-grid) {
            order: 2
        }

        &TopBar {
            margin-bottom: 40px;
            display: flex;
            align-items: center;

            &Search {
                display: flex;
                //background: #ffe07d;
                background: $primary-color;
                max-width: 600px;
                flex: 1 1 0;
                //color: #6E6893;
                color: #fff;
                align-items: center;
                border-radius: 1rem;
                padding-left: 20px;

                img {
                    height: 20px;
                    margin-right: 15px;
                }

                input {
                    border: none;
                    background: transparent;
                    outline: none;
                    font-family: "Inter", sans-serif;
                    flex: 1 1 0;
                    line-height: 46px;
                    height: 46px;
                    font-size: 15px;

                    &::placeholder {
                        //color: #6E6893;
                        color: #000;
                    }
                }
            }
            
            &Link {
                cursor: pointer;
            }

            &Home {
                margin-right: 5px;
                font-size: 19px;
                opacity: 90%;
                cursor: pointer;
                transition: $transition;

                &:hover {
                    color: $primary-color;
                }
            }

            & > ul {
                list-style: none;
                margin: 0 0 0 45px;
                padding: 0;
                flex: 1 1 0;
                align-items: center;
                display: flex;

                @include media-breakpoint-down($mobile-grid) {
                    display: none;
                }

                li {
                    @include animate(bdin, 200ms) {
                        from {
                            opacity: 0;
                            transform: translateX(-10px);
                        }
                        to {
                            opacity: 1;
                            transform: translateX(0);
                        }
                    }

                    &:nth-child(1) {
                        animation-duration: 200ms;
                    }
                    &:nth-child(2) {
                        animation-duration: 300ms;
                    }
                    &:nth-child(3) {
                        animation-duration: 400ms;
                    }
                    &:nth-child(4) {
                        animation-duration: 500ms;
                    }
                }

                li + li {
                    position: relative;
                    margin-left: 30px;

                    &:before {
                        text-align: center;
                        font-family: inherit !important;
                        font-size: 20px;
                        position: absolute;
                        content: '>';
                        left: -15px;
                        top: 55%;
                        line-height: 1;
                        transform: translate(-50%, -50%);
                        font-weight: 700;
                    }

                    &:last-child {
                        position: relative;

                        &:after {
                            content: '';
                            width: 95%;
                            height: 3px;
                            background: $primary-color;
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            bottom: -2px;
                        }
                    }
                }
            }
        }

        &Grid {
            display: flex;
            align-items: stretch;
            flex-wrap: wrap;
            margin: -8px;

            :global(&Card) {
                padding: 8px;
                flex: 0 1 33%;

                @include media-breakpoint-down(xxxl) {
                    flex: 0 1 50%;
                }
                @include media-breakpoint-down(xl) {
                    flex: 0 1 100%;
                }

                @include media-breakpoint-down($mobile-grid) {
                    flex: 0 1 100%;
                }

                &Category {
                    &:first-child > * {
                        border-top-left-radius: 3.5rem;
                    }

                    &:last-child > * {
                        border-bottom-right-radius: 3.5rem;
                    }

                    @include media-breakpoint-down($mobile-grid) {
                        & > * {
                            border-radius: 1.5rem !important;
                        }
                    }
                }
            }
        }
    }
}
















.catalogSection {
    position: relative;
}

.catalogPromotion {
    padding-top: 30px;
    margin-bottom: 20px;

    &Arrow {
        &Container {
            width: 90px !important;
            padding: 0 !important;
        }

        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        max-height: 220px;
        transform: rotate(8deg);
    }
}

.category:last-child {
    position: relative;
    font-weight: bold;
    color: #000 !important;
    cursor: default;

    &:after {
        position: absolute;
        content: "";
        width: 99%;
        height: 2px;
        background: $primary-color;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.category + .category {
    margin-left: 30px;
    position: relative;

    &:before {
        content: '>';
        position: absolute;
        left: -17px;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 18px;
        color: #000 !important;
    }
}

.categoryContainer {
    @include media-breakpoint-down(xl) {
        text-align: center;
    }
    z-index: 2;
}

.containGrid {
    @include media-breakpoint-up(xl) {
        max-height: 58vh;
        overflow-y: auto;
        overflow-x: hidden;
        margin: -32px !important;
        padding: 32px !important;
    }
}

/* scrollbar width */

.containGrid::-webkit-scrollbar {
    width: 10px;
}

/* scrollbar track */
.containGrid::-webkit-scrollbar-track {
    background: #eee;
}

/* scrollbar handle */
.containGrid::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: $primary-color;
    background-image: linear-gradient(
                    to top,
                    $primary-color 0%,
                    $fifth-color 100%
    );
}

.arrow {
    width: 100%;
    height: 120%;
    @include media-breakpoint-down(xl) {
        display: none;
    }
}

.siteButton {
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 4.87286px 8.12143px rgba(255, 185, 0, 0.2);
    width: fit-content;
    font-weight: 700;
    display: block;

    @include media-breakpoint-down(xl) {
        text-align: center;
        margin: 0 auto 2rem auto;
    }
}

.decoration {
    @include media-breakpoint-down(xl) {
        display: flex;
        justify-content: center;
    }
}
