// Utilities
@import "styles/utilities/variables";


.argument {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-transform: uppercase;
}
.number {
  color: $primary-color;
  margin-right: 20px;
  font-size: 30px;
  font-weight: 700 !important;
}

.title {
  font-weight: 600;
  width: 260px;
}
