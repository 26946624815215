@import "styles/utilities/variables";
@import "styles/utilities/animations";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

:global(.rc-picker) {
    &-dropdown {
        border: none;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 0 50px rgba(0, 0, 0, 0.15);
        border-radius: 14px;
        background: #fff;
        z-index: $date-picker-z-index;
    }

    &-named {
        padding-top: 40px;
    }

    &-footer-extra {
        position: absolute;
        top: 18px;
        font-weight: bold;
        left: 50%;
        transform: translateX(-50%);
    }

    &-header {
        &-super {
            &-prev, &-next {
                &-btn {
                    display: none;
                }
            }
        }

        &-view {
            display: flex;
            align-items: center;
            justify-content: center;

            & > button {
                background: none;
                font-family: "inter", sans-serif !important;
            }

            button + button {
                margin-left: 8px;
            }
        }

        & > button {
            background: transparent;
            border: none;
            outline: none;

            width: 35px;
            height: 35px;
            font-size: 20px;
            line-height: 35px;
            border-radius: 18px;
            padding: 0;
            color: #1672EC;
            transition: background 150ms ease-in-out;

            &:hover {
                background: rgba(197, 220, 250, 0.5);
            }
        }
    }

    &-panel {
        background: transparent;
        border: none;
        padding: 12px 15px;
    }

    &-content {
        table-layout: auto;
        width: 100%;
        font-family: "inter", sans-serif !important;
    }

    &-date-panel {
        width: 260px;

        thead {
            th {
                font-weight: normal;
                color: #9696A0 !important;
                font-size: 13px;
            }
        }
    }

    &-time-panel {
        margin-left: 25px;

        &-column {
            width: 56px;
            font-size: 13px;
            font-family: "inter", sans-serif;

            & + & {
                border-left: 1px solid #f0f0f0;
            }
        }

        &-cell-inner {
            height: 28px !important;
            line-height: 28px !important;
            margin: 0 !important;
            padding: 0 0 0 14px !important;
            transition: background 150ms ease-in-out;
        }

        &-cell {
            &:not(&-selected):hover &-inner {
                background: #C5DCFA !important;
            }

            &-selected &-inner {
                background: #1672EC !important;
                color: #fff !important;
                font-weight: bold;
            }
        }
    }

    &-cell-range-hover-start::after, &-cell-range-hover-end::after, &-cell-range-hover::after {
        border-color: #1672EC;
        border-style: dashed;
        top: -1px;
    }

    &-cell-range-hover-start::after {
        border-left: 1px dashed #1672EC !important;
        border-radius: 20px 0 0 20px;
    }

    &-cell-range-hover-end::after {
        border-right: 1px dashed #1672EC !important;
        border-radius: 0 20px 20px 0;
    }

    &-cell-range-start > &-cell-inner, &-cell-range-end > &-cell-inner, &-cell-selected > &-cell-inner {
        background: none !important;
        color: #fff;
    }

    &-cell-range-start, &-cell-range-end, &-cell-selected {
        position: relative;
    }

    &-cell-in-range > &-cell-inner {
        background: none !important;
    }

    &-cell-range-start:before, &-cell-range-end:before, &-cell-selected:before {
        background: #1672EC;
        position: absolute;
        top: -1px;
        left: 1px;
        width: 35px;
        height: 33px;
        content: '';
        z-index: 0;
        border-radius: 33px;
    }

    &-cell-range-start:not(&-cell-range-start-single):after, &-cell-range-end:after {
        background: #d0e3fb;
        opacity: 90%;
        position: absolute;
        top: 1px;
        width: 15px;
        height: 30px;
        content: '';
        z-index: -1;
    }

    &-cell-range-start:after {
        right: 0;
    }

    &-cell-range-end:after {
        left: 0;
    }

    :global(.rc-picker-cell-range-hover.rc-picker-cell-range-start):not(&-cell-range-hover-start):not(&-cell-range-hover-end)::after {
        right: unset;
        left: 0;
        height: 33px;
        top: -1px;
        width: 37px;
    }

    :global(.rc-picker-cell-range-hover.rc-picker-cell-range-start.rc-picker-cell-range-hover-start):not(&-cell-range-hover-end)::after {
        right: unset;
        left: 0;
        height: 33px;
        top: -1px;
        width: 37px;
    }

    &-cell-range-end#{&}-cell-range-hover-end::after {
        border-radius: 0 !important;
        height: 33px;
        top: -1px;
    }

    &-cell-range-start#{&}-cell-range-hover-start::after {
        width: 37px !important;
        top: -1px;
        height: 33px;
    }

    &-cell-range-end#{&}-cell-range-hover::after {
        width: 37px;
        top: -1px;
        height: 33px;
    }

    &-cell-in-range {
        position: relative;

        &:before {
            background: #d0e3fb;
            opacity: 90%;
            position: absolute;
            top: 1px;
            left: 0;
            width: 37px;
            height: 30px;
            content: '';
            z-index: -1;
        }
    }

    &-time-panel > &-header > &-header-view {
        line-height: 38px;
        font-family: "inter", sans-serif;
    }

    &-datetime-panel &-time-panel {
        border-left: 1px solid #f0f0f0;
    }

    &-month-panel, &-year-panel {
        width: 200px;
    }

    &-decade-panel {
        width: 330px;
    }

    &-month-panel, &-year-panel, &-decade-panel {
        .rc-picker-cell-inner {
            width: 100% !important;
            font-size: 15px !important;
        }
    }

    &-cell:not(&-cell-in-view):not(&-cell-in-view-start):not(&-cell-in-view-end) {
        display: none;
    }

    &-cell-inner {
        width: 30px !important;
        height: 30px;
        line-height: 30px;
        border-radius: 15px;
        z-index: 3;
        position: relative;
    }

    &-cell-today:not(&-cell-range-start):not(&-cell-selected):not(&-cell-range-end) &-cell-inner {
        background: #deebfc !important;
        border: none;
        font-weight: bold;
    }

    &-cell {
        &-today > &-inner {
            border: none;
        }
    }

    &-cell:not(&-cell-today):not(&-cell-selected):not(&-cell-range-start) &-inner:hover {
        background: #C5DCFA !important;
    }

    &-footer {
        background: transparent !important;
    }

    &-dropdown:not(&-dropdown-range) &-footer {
        display: none !important;
    }

    &-ranges {
        display: flex;
        justify-content: center;
    }

    &-ok button {
        background: #0078AA;
        border: none;
        outline: none;
        color: #fff;
        line-height: 28px;
        font-family: "Panton";
        font-weight: 700;
        text-align: center;
        width: 100px;
        padding: 0;
        border-radius: 14px;
        margin: 0 auto;
    }

    &-range-arrow {
        display: none;
    }

    &-range {
        border: 1px solid #ced4da;
        border-radius: 5px;
        line-height: 38px;
        height: 38px;
        padding: 0 10px;

        &-separator {
            width: 50px;
            text-align: center;
        }

        input {
            border: none !important;
            border-radius: 5px;
            outline: none !important;
            box-shadow: none !important;
        }
    }

    &-active-bar {
        display: none !important;
    }

    &-focused {
        border: 1px solid #ced4da;
    }
}