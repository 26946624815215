// Utilities
@import "styles/utilities/variables";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

:global .signup-widget {
    background: #fff;
}

.loginContainer {
    background-color: $sixth-color;
    flex: 1 1 0;
    display: flex;
    align-items: center;

    &Widget {
        flex: 1 1 0;
        background: #fff;
    }

    @include media-breakpoint-up(xl) {
        min-height: 100vh;
        padding-top: 30px;
        background-image: url("./assets/luxembourgLogo.svg"),
        url("./assets/background.svg");
        background-position: left 0px bottom 0px, left 0px top 0px;
        background-size: 50% 90%, contain;
        background-repeat: no-repeat, no-repeat;
    }

    @include media-breakpoint-down(xl) {
        position: fixed;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        background: #f4bb24;

        &:before {
            z-index: 0;
            background: #fff;
            width: 100%;
            height: 150px;
            content: '';
            top: 0;
            left: 0;
            position: absolute;
        }

        &:after {
            background: url("./assets/mobile.svg") no-repeat;
            background-size: 100%;
            position: absolute;
            top: 140px;
            height: calc(100% - 190px);
            width: 100%;
            z-index: 1;
            content: '';
        }
    }
}

.formContainer {
    padding: 0 20px;
    text-align: center;

    &Wrapper {
        margin-top: 50px;

        & > form {
            width: 100%;
            max-width: 520px;
            margin-left: auto;
            margin-right: auto;

            @include media-breakpoint-down(xl) {
                & > :global.row > div {
                    & + div {
                        margin-top: 30px;
                    }
                }

                & > button {
                    background: $black-color;
                }
            }
        }
    }

    @include media-breakpoint-down(xl) {
        z-index: 2;
        width: 100%;
    }
}

.logo {
    min-width: 230px;
    max-width: 350px;
    width: 50vw;
    margin-top: 50px;
    margin-bottom: 110px;
    z-index: 3;
    display: none;

    @include media-breakpoint-down(xl) {
        display: block;
    }
}

.back {
    display: table;
    margin: 0 auto 20px;
    color: #2c3e50;
    padding: 8px 15px;
    border-radius: 20px;
    font-weight: 700;
    cursor: pointer;
}

.title {
    font-size: 55px;
    color: #1e0e62;

    @include media-breakpoint-down(xl) {
        font-size: 40px;
        line-height: 48px;
        color: #3B3B3B;
        margin: 0;
    }
}

.subTitle {
    font-size: 20px;
    color: #000;
    opacity: 30%;
    font-weight: 300;
    line-height: 24px;

    @include media-breakpoint-up(xl) {
        display: none;
    }
}

.loginInput {
    height: 55px;
    line-height: 55px;
    border-radius: 30px !important;
    font-family: "Panton";
    border: 2px solid #EBEAED;
    font-weight: 700;
    padding: 0 30px !important;

    @include media-breakpoint-down(xl) {
        border-radius: 10px !important;
        border: none !important;
        box-shadow: 0 0 20px rgba(29, 40, 142, 0.07) !important;
    }
}

.lineBreak {
    border-bottom: 2px solid #ebeaed;

    @include media-breakpoint-down(xl) {
        display: none;
    }
}

.callToAction {
    font-weight: 700;
    font-size: 20px;

    span {
        display: block;
        color: #008bda;
        cursor: pointer;
    }

    @include media-breakpoint-down(xl) {
        .slogan {
            display: none;
        }

        display: flex;
        justify-content: space-between;
        margin-top: 36px;
        margin-bottom: 20px;

        span {
            font-size: 14px;

            &.lostPassword {
                font-weight: 300;
                color: #000;
                opacity: 80%;
                order: 1;
            }

            &.signUp {
                font-weight: 500;
                color: #13497B;
                order: 2;
            }
        }
    }
}

.loginBtn {
    width: 100%;
    height: 55px;
    background: #FBB228;
    border-radius: 45px;
    box-shadow: none;
    transition: background 150ms ease-in-out;

    &:hover {
        background: #eea926 !important;
        color: #fff;
    }

    @include media-breakpoint-down(xl) {
        background: $second-color;
        line-height: 55px;
        padding: 0;
        height: 55px;
        box-shadow: 0 0 20px rgba(29, 40, 142, 0.05);
        border-radius: 10px !important;
    }
}

.cookie {
    &Band {
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: $second-color;
        display: flex;
        justify-content: space-around;
        align-items: center;
        box-shadow: 0 -5px 14px -4px rgba(0, 0, 0, 0.3);
    }

    &Content {
        font-family: "Panton";
        font-weight: 700;
        color: #fff;
        padding: 20px 12px;
    }

    &Button {
        box-shadow: 0px 0px 10px rgba(29, 22, 14, 0.35);
        border-radius: 15px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        margin: 12px;
        padding: 5px 30px;
        text-align: center;
        border: none;
        background: $primary-color;
        color: $fifth-color;

        &:hover {
            background: $fifth-color;
            color: $third-color;
        }
    }
}
