@import "styles/utilities/variables";
@import "styles/utilities/animations";

.loader {
    display: inline-block;
    position: relative;
    vertical-align: -0.25em;

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        border-radius: 50%;
        border-style: solid;
        border-color: #fff transparent transparent transparent;
        animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);

        @include animate(loader, 950ms, true) {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }
    }

    &Dark div {
        border-color: #34495e rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.2);
    }

    &Primary div {
        border-color: $seventh-color rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.2);
    }
}