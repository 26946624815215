// Utilities
@import "styles/utilities/variables";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.menuCard {
  background-color: $fifth-color;
  border-radius: 10px;
  cursor: pointer;
  min-height: $menu-card-height;
  display: flex;
  align-items: center;

  &Icon {
    align-self: flex-start;
    margin: 4px 10px 0 17px;
    flex: 0 0 0;
  }

  &Content {
    flex: 1 1 0;
  }

  h6 {
    font-family: "Poppins";
    font-weight: 500;
  }

  &.active {
    h6 {
      color: $fourth-color;
    }
  }
}

.info{
  font-size: 12px;
  color: #626262;
}

.active {
  background-color: #E0E0E0;
  cursor: default;
}

.menuCard:not(.active):hover {
  background-color: $primary-color;
}

.menuName{
  font-weight: 700;
}
.Logo {
  width: 20px;
}

.space {
  @include media-breakpoint-down(xl) {
    display: none !important;
  }
}

//hide default menu for mobile
@include media-breakpoint-down(xl) {
  .menuContainer {
    display: none;
  }
}