.modal {
  &-backdrop {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    background-color: $modal-backdrop-color !important;
    z-index: $modal-z-index-backdrop !important;
  }

  &-container {
    position: absolute;
    background: #fff;
    border-radius: 25px;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .25);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none !important;
  }

  &-close {
    cursor: pointer;
    top: 15px;
    right: 15px;
    position: absolute;
  }
}