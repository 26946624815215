@import "styles/utilities/variables";
@import "styles/utilities/animations";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.productDetail {
    width: 600px;
    display: flex;
    flex-direction: column;
    padding: 0 !important;

    @include media-breakpoint-down($mobile-grid) {
        width: 100%;
    }

    &Body {
        padding: 60px 0 70px;
        overflow: auto;
        position: relative;

        h1 {
            font-size: 23px;
            font-weight: 700;
            color: $seventh-color;
            line-height: 28px;
            text-align: center;
            text-transform: uppercase;
            padding: 0 50px;
        }

        &Pic {
            position: relative;
            margin-top: 50px;
            margin-bottom: 60px;

            & > img:not(&Energy) {
                max-height: 180px;
                display: block;
                margin: 0 auto;
            }

            &Energy {
                position: absolute;
                bottom: 0;
                right: 30px;
            }
        }

        &Tab {
            padding: 0 75px;

            @include media-breakpoint-down($mobile-grid) {
                padding: 0 20px;
            }

            & + & {
                margin-top: 60px;
            }

            &Title {
                width: 100%;
                position: relative;
                margin-bottom: 25px;

                p {
                    background: #2F3132;
                    display: table;
                    font-family: Montserrat, sans-serif;
                    text-transform: uppercase;
                    color: #fff;
                    font-weight: 500;
                    padding: 0 15px;
                    line-height: 35px;
                    border-radius: 12px;
                    font-size: 0.9em;
                }

                &:after {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 90%;
                    content: '';
                    height: 1px;
                    background: #2F3132;
                }
            }

            &Body {
                padding: 0 10px;

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    li {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 14px 5px;

                        p {
                            margin: 0;
                            padding: 0;line-height: 1;
                        }

                        p:first-child {
                            font-weight: bold;
                        }

                        p:last-child {
                            color: $second-font-color;
                        }
                    }

                    li + li {
                        border-top: 1px solid rgba(0, 0, 0, 0.14);
                    }
                }
            }
        }

        &Rent {
            background: #F6B42F;
            width: 80%;
            margin: 80px auto 0;
            border-radius: 2rem;
            padding: 30px;

            @include media-breakpoint-down($mobile-grid) {
                width: 90%;
            }

            h3 {
                color: #fff;
                margin: 0 0 30px;
                text-align: center;
                line-height: 1;
                font-size: 28px;
            }

            &Alert {
                background: #fae5d3;
                width: 80%;
                margin: 60px auto 0;
                border-radius: 2rem;
                padding: 20px 30px;
            }
        }
    }
}