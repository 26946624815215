@import "styles/utilities/variables";
@import "styles/utilities/animations";
@use "sass:selector";

:global #root {
    transition: all 200ms ease-in-out;
}

.modal {
    background: #fff;
    border-radius: 18px;
    box-sizing: border-box;
    position: relative;
    top: 15%;
    margin: 0 auto;
    z-index: 101;
    transition: all ease-in-out;
    padding: 25px;
    box-shadow: 0 0 40px 4px rgba(0, 0, 0, 0.16);

    @include media-breakpoint-down($mobile-grid) {
        position: fixed;
        width: 100% !important;
        height: 100% !important;
        top: unset;
        border-radius: 0;
    }

    &Sidebar {
        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 0;
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
    }

    &Close {
        position: absolute;
        top: 15px;
        right: 15px;
        transition: all 200ms ease-in-out;
        width: 36px;
        height: 36px;
        display: block;
        background: transparent;
        border-radius: 18px;
        cursor: pointer;
        z-index: 2001;

        &:hover {
            background: rgba(0, 0, 0, 0.1);
        }

        img {
            width: 17px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &Body {
        overflow: hidden;
        position: relative;
        filter: blur(2px);
    }

    &Root {

    }

    &Opening:not(&Sidebar) {
        animation-play-state: paused;

        @include animate(modal-opening, 300ms) {
            0% {
                transform: translateY(-150%);
                opacity: 0;
            }
            20% {
                opacity: 1;
            }
            40% {
                transform: translateY(20px);
            }
            70% {
                transform: translateY(-10px);
            }
            100% {
                transform: translateY(0);
            }
        }
    }

    &Opening.modalSidebar {
        animation-play-state: paused;

        @include animate(modal-opening-right, 200ms) {
            0% {
                transform: translateX(150%);
                opacity: 0;
            }
            100% {
                opacity: 1;
                transform: translateX(0);
            }
        }
    }

    &Closing:not(&Sidebar) {
        animation-play-state: paused;

        @include animate(modal-closing, 300ms) {
            0% {
                transform: translateY(0);
            }
            35% {
                transform: translateY(30px);
            }
            80% {
                opacity: 1;
            }
            100% {
                transform: translateY(-100%);
                opacity: 0;
            }
        }
    }

    &Closing.modalSidebar {
        animation-play-state: paused;

        @include animate(modal-closing-right, 200ms) {
            0% {
                transform: translateX(0);
                opacity: 1;
            }
            100% {
                opacity: 0;
                transform: translateX(150%);
            }
        }
    }

    &.modalActive.modalOpening, &.modalActive.modalClosing {
        animation-play-state: running;
    }

    &Backdrop {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        background: rgba(0, 0, 0, 0.22);
        z-index: 100;

        &Opening, &Closing {
            animation-duration: 300ms;
            animation-timing-function: ease-in-out;
            animation-fill-mode: both;
        }

        &Opening {
            animation-name: fadeIn;
        }

        &Closing {
            animation-name: fadeOut;
        }
    }

    &Wrap {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        outline: 0;
        -webkit-overflow-scrolling: touch;
        z-index: 1000;
    }
}