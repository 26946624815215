$primary-color: #F4BB25;
$second-color: #0093e9;
$third-color: #000000;
$fourth-color: #FB6D3A;
$fifth-color: #ffffff;
$sixth-color: #FAFAFA;
$seventh-color: #FFA115;
$eighth-color: #6E6893;
$danger-color: #e55039;

$green-color: #6ab04c;
$red-color: #e55039;

$gray-color: #a2b5cd;
$content-color: #F8F8F8;
$font-color: $third-color;
$second-font-color: #484e57;
$third-font-color: #7f8389;
$black-color: #2F3132;

$input-border-color: #becbda;

$box-shadow: 3px 6px 9px #ccc;

$modal-backdrop-color: rgba(102, 100, 100, 0.8);
$modal-z-index-backdrop: 9999;

$date-picker-z-index: 9999;
$notification-dropdown-z-index: 9998;
$notification-z-index: 99999;
$transition: all 0.2s ease-in-out;

// navigation
$nav-corner-size: 30px;
$nav-height: 39px;
$top-nav-height: 46px;
$top-nav-icon-size: 21px;

//menu card
$menu-card-height: 76px;

$mobile-grid: mobile;

/* set the overriding variables */
$grid-breakpoints: (
        xxxs: 0,
        xxs: 365px,
        xs: 568px,
        sm: 667px,
        md: 768px,
        mobile: 940px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px,
        xxxl: 1600px,
);
$container-max-widths: (
        xxxs: 0,
        xxs: 320px,
        xs: 568px,
        sm: 667px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px,
        xxxl: 1600px
);

@mixin scrollbar($width: 10px) {
    &::-webkit-scrollbar {
        width: $width;
        cursor: pointer;
    }

    &::-webkit-scrollbar-track {
        background: #eee;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 1rem;
        background-color: $primary-color;
        background-image: linear-gradient(
                        to top,
                        $primary-color 0%,
                        $fifth-color 100%
        );
    }
}

@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";