.confirmModal {
    width: 540px;
    padding-left: 40px;
    padding-right: 40px;

    &Cone {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-38%, -70%);
    }

    &Title {
        font-weight: 700;
        font-family: Inter, sans-serif;
        text-transform: uppercase;
        font-size: 1.2em;
        margin: 25px 0 20px 0;
    }

    &Body {
        font-family: Inter, sans-serif;
    }

    &Footer {
        margin-top: 40px;
        display: flex;
        align-items: stretch;
        justify-content: flex-end;

        & > * + * {
            margin-left: 20px;
        }
    }
}