// Utilities
@import "styles/utilities/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.menuContainer,
.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@include media-breakpoint-up(lg) {
  .settingsContainer {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    &:before {
      position: absolute;
      content: '';
      height: 400px;
      width: 300px;
      top: 0;
      right: 0;
      background: url("/assets/kamat-side.svg") no-repeat top 60px right 0, url("/assets/background.svg") no-repeat top 0 right 0;
      background-size: 35px auto, 400px 300px;
      z-index: 0;
    }
  }
}

@include media-breakpoint-down(xl) {
  .settingsContainer:before {
    border-top-right-radius: 15px;
  }
}

@include media-breakpoint-up(sm) {
  padding-right: 55px !important;
}

@include media-breakpoint-down(xl) {
  .settingsContainer {
    margin-top: -30px;

    &:before {
      top: 30px;
      border-top-right-radius: 15px;
    }
  }
}