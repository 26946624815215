// Utilities
@import "styles/utilities/variables";
@import "styles/utilities/animations";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.siteManager {
    &TopBar {
        display: flex;
        align-items: center;
        margin-top: -80px;
        margin-bottom: 30px;
        min-height: 200px;

        @include media-breakpoint-down($mobile-grid) {
            display: none;
        }

        &Logo {
            height: 100%;
            max-height: 200px;

            @include animate(logoIn, 500ms) {
                from {
                    transform: translateX(-40px);
                    opacity: 0;
                }
                to {
                    transform: translateX(0);
                    opacity: 1;
                }
            }
        }

        &Logo + &Box {
            margin-left: auto;
        }

        &Box {
            background-color: $fifth-color;
            border-radius: 18px;
            box-shadow: 0 0 30px -5px rgba(167, 167, 167, 0.7);
            text-align: center;
            display: flex;
            align-items: center;
            padding: 30px 25px;

            img {
                margin-right: 20px;
            }

            h5, h6 {
                line-height: 1;
                margin: 0;
            }

            h5 {
                margin-bottom: 5px;
            }
        }
    }

    &Selector {
        display: flex;
        align-items: center;
        position: relative;

        @include media-breakpoint-down($mobile-grid) {
            flex-direction: column;
            width: 100%;
        }

        & > * {
            position: relative;
        }

        a {
            padding: 17px 20px;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.12);
            z-index: 1;
            line-height: 1;
            border: 1px solid #E0E0E0;
            transition: $transition;
            text-align: center;

            @include media-breakpoint-down($mobile-grid) {
                width: 100%;
            }

            &:first-child {
                border-radius: 14px 0 0 14px;
                margin-right: -20px;
                padding-right: 35px;

                @include media-breakpoint-down($mobile-grid) {
                    padding: 20px 20px 30px 20px !important;
                    margin: 0;
                    border-radius: 17px 17px 0 0;
                    border-bottom-width: 0;
                }
            }

            &:last-child {
                border-radius: 0 14px 14px 0;
                margin-left: -20px;
                padding-left: 35px;

                @include media-breakpoint-down($mobile-grid) {
                    border-radius: 0 0 17px 17px;
                    margin: 0;
                    padding: 30px 20px 20px 20px !important;
                }
            }
        }

        & > a:not(&Active) {
            background: #fff;
            cursor: pointer;

            &:hover {
                color: $fourth-color;
            }
        }

        &Active {
            background: #fadd92;
            cursor: default;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.12), 0 0 0 3px #fff inset !important;
            color: inherit !important;
        }

        &Icon {
            width: 58px;
            height: 58px;
            border-radius: 40px;
            background: #fff;
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
            border: 1px solid #E0E0E0;
            z-index: 2;

            @include media-breakpoint-down($mobile-grid) {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 50px;
                height: 50px;
            }

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-40%, -50%);
                width: 30px;

                @include media-breakpoint-down($mobile-grid) {
                    width: 28px;
                }
            }
        }
    }

    &AddButton {
        background: none;
        border: none;
        box-shadow: none;
        font-weight: 700;
        min-width: 200px;

        @include media-breakpoint-down($mobile-grid) {
            min-width: unset;
            margin-top: 10px;
        }

        img {
            vertical-align: -9px;
            display: inline-block;

            @include media-breakpoint-down($mobile-grid) {
                margin: 0 8px 0 0 !important;
            }
        }
    }

    &Header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media-breakpoint-down($mobile-grid) {
            flex-direction: column;
        }

        &Tools {
            display: flex;
            align-items: center;
            flex: 1 1 0;
            margin-left: 30px;
            justify-content: flex-end;
            
            :global .km-search {
                max-width: 350px;
            }

            @include media-breakpoint-down($mobile-grid) {
                flex-direction: column;
                margin-left: 0;
                width: 100%;
                align-items: flex-start;

                :global .km-search {
                    width: 100%;
                    max-width: unset;
                    margin-top: 30px;
                }
            }
        }
    }

    &Body {
        margin-top: 30px;

        &Empty {
            margin-top: 50px;
            display: flex;
            align-items: center;

            img {
                height: 50px;
            }

            p {
                color: #484e57;
                font-family: Inter, sans-serif;
                margin: 0 0 0 20px;
            }
        }
    }

    &Loading {
        text-align: center;
        margin-top: 50px;

        div {
            vertical-align: -5px;
            margin-right: 6px;
        }
    }
}