// Utilities
@import "styles/utilities/variables";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.offerTable {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;

    @include media-breakpoint-down($mobile-grid) {
        margin-top: 30px;
    }

    &Container + &Container {
        margin-top: 80px;
    }
}

.offerLine {
    display: flex;
    align-items: center;
    cursor: pointer;
    background: #fff;
    transition: background 100ms ease-in-out;
    flex-wrap: wrap;
    border-radius: 4px;

    &Body {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex: 1 1 0;
    }

    & + & {
        border-top: 1px solid #D9D5EC;
        margin-top: 12px;
        padding-top: 12px;
    }

    p {
        line-height: 1;
        margin: 0;
    }

    &New {
        padding-right: 20px;

        span {
            background-color: $primary-color;
            background-image: linear-gradient(to right, $primary-color, #f39c12 80%);
            color: #fff;
            text-transform: uppercase;
            line-height: 1;
            font-family: Poppins, sans-serif;
            padding: 6px 10px;
            border-radius: 12px;
            transform: rotate(-15deg);
            margin-top: -5px;
            display: block;
        }
    }

    &Info {
        flex: 1 1 0;
        color: $second-font-color;
    }

    &Logo {
        background: #fff;
        position: relative;
        box-shadow: 0 0 16px -2px rgba(0, 0, 0, 0.27);
        border-radius: 1rem;
        width: 60px;
        height: 60px;
        margin-right: 20px;

        img {
            max-width: 48px;
            max-height: 48px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &BtnPrimary, &BtnSecondary {
        transform: translateY(-80%);
        position: absolute;
        background: $seventh-color;
        padding: 8px 23px;
        font-family: Poppins, sans-serif;
        color: #fff !important;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 14px;
        box-shadow: 0 10px 17px 0 rgba(40, 40, 40, 0.15);
        display: table;
        margin: 0 0 0 auto;
        border-radius: 27px;
        cursor: pointer;
        transition: all 200ms;
        border: none !important;

        &:hover, &:active {
            background: #e6af00;
        }
    }

    &BtnSecondary {
        background: #bdc3c7;

        &:hover, &:active {
            background: #979c9f;
        }
    }

    &Tools {
        width: 165px;
        margin-left: 40px;

        @include media-breakpoint-down($mobile-grid) {
            flex: 1 1 100%;
            margin: 12px 0 8px;

            & > button {
                margin: 0 auto !important;
                width: 100%;
                line-height: 40px;
                height: 40px;
            }
        }
    }

    &Active {
        cursor: default;
        background: #fef5de;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15), 0 0 0 5px #fff inset;
    }

    &Active &Name {
        color: $seventh-color;
    }

    &Name {
        flex: 1 1 100%;
        font-weight: 500;
        line-height: 1;
        font-size: 1.1em;
        margin-bottom: 5px;
    }

    &Date {
        font-weight: normal;
        color: $second-font-color;
        font-size: 0.9em;
    }

    &OfferTotal {
        margin-right: 20px;

        @include media-breakpoint-down($mobile-grid) {
            flex: 1 1 100%;
            margin-bottom: 4px;
        }
    }

    &Expiration {
        width: 220px;
        text-align: right;

        &Expired {
            text-align: center;
            color: #c0392b;
            font-weight: 500;
        }
    }

    &State {
        width: 220px;

        span {
            line-height: 30px;
            border-radius: 15px;
            color: #fff;
            display: block;
            margin-left: auto;
            width: 120px;
            text-align: center;
            font-weight: 500;
        }

        &Success {
            background: $green-color;
        }

        &Danger {
            background: $red-color;
        }
    }

    &Total {
        flex: 1 1 0;
        text-align: right;
    }
}

