@import "styles/utilities/variables";
@import "styles/utilities/animations";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.cartAddForm {
    position: relative;

    &Error {
        background: #fff;
        padding: 10px;
        text-align: center;
        border-radius: 12px;
        margin-bottom: 20px;
        color: $danger-color;
        font-weight: 700;
    }

    &Range {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media-breakpoint-down($mobile-grid) {
            flex-direction: column;
        }

        &Input {
            display: flex;
            align-items: center;
            background: #fff;
            padding: 0 15px 0 15px;
            border-radius: 12px;
            max-width: 48%;

            @include media-breakpoint-down($mobile-grid) {
                max-width: unset;
                width: 100%;

                & + & {
                    margin-top: 10px;
                }
            }

            &Picker, &Picker input {
                line-height: 40px;
                border: none !important;
                outline: none !important;
                box-shadow: none !important;
                padding: 2px 0 0 0;

                @include media-breakpoint-down($mobile-grid) {
                    flex: 1 1 0;
                }
            }

            input::placeholder {
                color: $third-font-color !important;
            }

            svg {
                height: 18px;
                color: $primary-color;
            }
        }
    }

    &Options {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 20px 0 -8px;

        & > * {
            flex: 1 1 50%;
            margin-bottom: 8px;
        }
    }

    &Btn {
        background: #2F3132;
        color: #fff;
        border: none;
        outline: none;
        line-height: 40px;
        padding: 2px 25px 0;
        border-radius: 20px;
        font-weight: 700;
        display: table;
        margin: 30px auto 0;
    }
}