// Utilities
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "styles/utilities/variables";

.formularInput {
  color: #6b6b6b;
  background: rgba(242, 242, 242, 0.97);
  border-radius: 5px;
  padding-left: 30px;
}

.buttonNotFirst {
  margin-left: 10px;
}

.errorText {
  color: $danger;
}
