// Utilities
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "styles/utilities/variables";

.form {
    margin-top: 30px;
}

.alert {
    width: 100%;
    padding: 20px;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    &Success {
        background: #d6edd3;
        color: #275D3B;
    }

    &Error {
        color: #c0392b;
        background: #f9ebea;
    }

    p {
        margin: 0;
    }

    & > div, & > p {
        flex: 1 1 0;
    }

    & > svg {
        font-size: 35px;
        margin-right: 15px;
        opacity: 80%;
    }
}

.errorText {
    color: $danger;
    font-size: 0.9em;
    text-align: left;
    margin-top: 3px;
    margin-left: 7px;
    font-weight: 700;
}

.error {
    background: #fadbd8;
    color: #b93d30;
    display: flex;
    align-items: center;
    padding: 15px 20px;
    border-radius: 12px;
    margin-bottom: 30px;

    p {
        line-height: 1;
        margin: 0;
        font-family: Inter, sans-serif;
        font-weight: 500;
    }

    svg {
        font-size: 22px;
        margin-right: 10px;
    }
}