// Utilities
@import "styles/utilities/variables";
@import "styles/utilities/animations";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.invoices {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        display: flex;
        align-items: center;

        @include media-breakpoint-down($mobile-grid) {
            flex-wrap: wrap;
        }
    }

    li + li {
        border-top: 1px solid #D9D5EC;
        margin-top: 18px;
        padding-top: 18px;
    }

    &Reference {
        font-weight: 500;
        flex: 1 1 40%;
        line-height: 1.4em;
        margin: 0;

        @include media-breakpoint-down($mobile-grid) {
            flex: 1 1 100%;
            margin-bottom: 15px;
        }

        small {
            display: block;
            color: $second-font-color;
            font-weight: 400;
            margin-top: 2px;

            svg {
                margin-right: 5px;
                color: $seventh-color;
            }

            b {
                font-weight: 500;
            }
        }
    }

    &Amount {
        flex: 1 1 30%;
        font-weight: 500;
        font-size: 1.2em;
        line-height: 1;

        @include media-breakpoint-down($mobile-grid) {
            flex: 1 1 50%;
            max-width: 50%;
            margin: 0;
            font-size: 1.4em;
        }
    }

    &Date {
        flex: 1 1 20%;
        color: $second-font-color;
        margin: 0 30px 0 0;
        line-height: 1;
        white-space: nowrap;

        @include media-breakpoint-down($mobile-grid) {
            margin: 0;
            flex: 1 1 50%;
        }
    }

    a {
        font-size: 26px;
        transition: $transition-base;
        color: $third-font-color;

        @include media-breakpoint-down($mobile-grid) {
            margin-top: 10px;
        }

        &:hover {
            color: $seventh-color;
        }
    }

    a + a {
        margin-left: 15px;
    }
}

.empty {
    display: flex;
    align-items: center;

    p {
        line-height: 1;
        margin: 0;
        margin-left: 40px;
    }
}

@include media-breakpoint-down($mobile-grid) {
    .search {
        margin-top: 20px;
        width: 100%;
    }
}