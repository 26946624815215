@import "styles/utilities/variables";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.settings {
    margin-top: 25px;
    z-index: 1;
    position: relative;

    &Title {
        text-transform: uppercase;
        font-family: "Panton", sans-serif;
        font-size: 24px;
        margin-bottom: 30px;
    }

    &SectionTitle {
        font-size: 1.1em;
        font-weight: 500;
        margin: 0 0 35px 0;

        svg {
            color: $second-font-color;
            font-size: 23px;
            vertical-align: -4px;
            margin-right: 7px;
        }
    }

    &Helper {
        color: $third-font-color;
        font-size: 0.9em;

        svg {
            margin-right: 3px;
            opacity: 80%;
        }
    }

    &SocietyLogo {
        display: flex;
        align-items: center;

        &Img {
            border: 1px solid $input-border-color;
            padding: 8px;
            border-radius: 12px;
            margin-right: 30px;
        }
    }

    &Info, &Success {
        padding: 20px;
        border-radius: 1rem;
        margin-bottom: 20px;

        p {
            margin: 0;
        }

        p + p {
            margin-top: 5px;
        }
    }

    &Info {
        background: #d3edf0;
        color: #11535a;
    }

    &Success {
        display: flex;
        align-items: center;
        background: #e1efdb;
        color: #406a2e;
        margin-bottom: 40px;

        p:first-child {
            font-size: 1.1em;
        }

        p + p {
            margin-top: 2px;
        }

        & > svg {
            font-size: 30px;
            margin-right: 20px;
        }
    }

    &Steps {
        list-style: none;
        margin: 20px 0 40px 0;
        border-bottom: 2px dashed rgba(0, 0, 0, 0.1);
        padding: 0 0 25px 0;

        li {
            display: flex;
            align-items: center;

            & > span {
                background: #e5e9ea;
                width: 35px;
                line-height: 35px;
                text-align: center;
                font-weight: 600;
                font-size: 16px;
                color: #34495e;
                border-radius: 10px;
                margin-right: 12px;
            }

            p {
                margin: 0;

                span {
                    font-weight: 600;
                    font-size: 0.8em;
                    margin-left: 15px;
                    text-transform: uppercase;

                    svg {
                        font-size: 1.2em;
                        margin-right: 5px;
                        vertical-align: -2px;
                    }
                }
            }
        }

        &Validate {
            color: $success;
        }

        &Waiting {
            color: rgba(0, 0, 0, 0.4);
        }

        li + li {
            margin-top: 8px;
        }
    }

    &Payouts {
        list-style: none;
        padding: 0;
        margin: 0;

        &Empty {
            display: flex;
            flex-direction: column;
            align-items: center;

            svg {
                color: $gray-color;
                font-size: 65px;
                margin-bottom: 15px;
                opacity: 50%;
            }
        }

        li {
            display: flex;
            align-items: center;
        }

        li + li {
            margin-top: 15px;
            padding-top: 15px;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
        }

        p {
            margin: 0;
        }

        &Content {
            flex: 1 1 40%;

            p:first-child {
                font-weight: 500;
            }

            p + p {
                color: $third-font-color;
                font-size: 0.9em;
            }
        }

        &Date {
            color: $second-font-color;
            flex: 1 1 20%;
            font-size: 0.9em;
        }

        &Amount {
            flex: 1 1 10%;
        }

        &Status {
            flex: 1 1 10%;
            text-align: right;

            span {
                padding: 6px 12px;
                font-size: 0.9em;
                font-weight: 500;
                border-radius: 1rem;
            }
        }

        &Success {
            background: #e1efdb;
            color: #406a2e;
        }

        &Waiting {
            color: #596973;
            background: #dfe7ec;
        }
    }
}