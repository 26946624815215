@import "styles/utilities/variables";

a.button {
    text-align: center;
}

.button {
    border-style: solid;
    border-width: 1px;
    transition: $transition;
    border-radius: 10px;
    line-height: 42px;
    height: 42px;
    padding: 0 18px;
    font-family: Poppins, sans-serif !important;
    position: relative;

    &Bold {
        font-weight: 500;
    }

    &Rounded {
        border-radius: 30px;
    }

    &[disabled], &Disabled {
        opacity: 50%;
        cursor: default !important;
    }

    &Loading {
        width: 70px;
        cursor: wait !important;
    }

    &Loader {
        position: absolute !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    //sizes
    &Small {
        line-height: 32px;
        height: 32px;
        padding: 0 12px;
    }

    &Large {
        line-height: 48px;
        height: 48px;
        font-size: 1.1em;
        padding: 0 22px;
    }

    //icons
    &Icon {
        font-size: 22px;
        vertical-align: -5px;
        margin-right: 11px;
    }

    &Small &Icon {
        font-size: 16px;
        vertical-align: -2px;
        margin-right: 7px;
    }

    //colors
    &Black {
        border-color: #2F3132;
        background: #2F3132;
        color: #fff;

        &.buttonOutline {
            color: #2F3132;

            &:hover {
                color: #fff;
                background: #2F3132;
            }
        }

        &:not(.buttonOutline):hover {
            opacity: 85%;
        }
    }

    &Gray {
        background: #bdc3c7;
        border-color: #bdc3c7;
        color: #fff;

        &.buttonOutline {
            color: #979c9f;

            &:hover {
                color: #fff;
                background: #bdc3c7;
            }
        }
    }

    &Danger {
        background: #e55039;
        border-color: #e55039;
        color: #fff;

        &:hover, &:active {
            background: #b7402e;
        }

        &.buttonOutline {
            color: #e55039;

            &:hover {
                color: #fff;
            }
        }
    }

    &Yellow {
        border-color: #E9A82F;
        background: #E9A82F;
        color: #fff;

        &.buttonOutline {
            color: #E9A82F;

            &:hover {
                color: #fff;
                background: #E9A82F;
            }
        }

        &:not(.buttonOutline):hover {
            opacity: 85%;
            color: #fff;
        }
    }

    &Blue {
        background: #008BDA;
        color: #fff;
        border-color: #008BDA;

        &.buttonOutline {
            color: #008BDA;
        }
    }

    &Outline {
        background: #fff;
    }

}