@import "styles/utilities/variables";

.documentUploader {
    &:not(&Secondary) {
        border: 1px solid $input-border-color;
        border-radius: 12px;
        padding: 20px 25px;
    }

    & + & {
        margin-top: 20px;
    }

    &Body {
        display: flex;
        align-items: center;
    }

    &Content {
        margin-left: 20px;
        flex: 1 1 0;
    }

    p + &Secondary {
        margin-top: 30px;
    }

    &Secondary + &Secondary {
        margin-top: 18px;
        padding-top: 18px;
        border-top: 1px solid #d2dbe5;
    }

    &Secondary {
        margin-left: 20px;

        &Wrapper {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 2px dashed #d2dbe5;
        }

        &Title {
            margin: 0 0 5px 0;
            text-align: right;
            font-weight: 500;
        }

        &Note {
            margin: 0 0 0 auto;
            text-align: right;
            max-width: 60%;
            line-height: 1.2em;
            color: $second-font-color;
        }

        &Btn {
            display: table;
            margin: 30px 0 0 auto;
        }
    }

    &Title {
        margin: 0;
    }

    &Error {
        color: $danger-color;
        font-weight: 500;
        font-size: 0.9em;
        margin: 0;
    }

    &Warning {
        margin: 0;
        color: #e67e22;
        font-weight: 500;
    }

    &Icon {
        width: 55px;
        text-align: center;

        & > svg {
            font-size: 40px;
            color: $gray-color;
        }
    }

    &Status {
        padding: 12px 20px;
        display: flex;
        align-items: center;
        border-radius: 12px;

        p {
            line-height: 1;
            margin: 0;
            font-weight: 500;
            text-transform: uppercase;
        }

        svg {
            font-size: 22px;
            margin-left: 14px;
        }

        &:global(.yellow) {
            background: #fdebd0;
            color: #db8c10;
        }

        &:global(.blue) {
            background: #d3edf0;
            color: #1b858f;
        }

        &:global(.red) {
            background: #f0d7d7;
            color: #8f2e2e;
        }

        &:global(.green) {
            background: #e1efdb;
            color: #5f9e44;
        }
    }

    &Modal {
        width: 550px;
        padding: 30px 45px;

        h2 {
            font-size: 23px;
            text-align: center;
            margin-bottom: 30px;
        }

        &VersoHelper {
            font-size: 0.9em;
            margin-top: 5px;
            color: $second-font-color;
            text-align: center;
        }

        & > label {
            font-size: 0.9em;
            color: $second-font-color;
            font-weight: 500;
        }
    }
}