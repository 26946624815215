@import "styles/utilities/variables";

.tabs {
    &Header {
        display: flex;
        align-items: flex-end;
        width: 100%;
        justify-content: space-between;

        @include media-breakpoint-down($mobile-grid) {
            flex-direction: column;
            align-items: flex-start;
            z-index: 999;
            position: relative;
        }

        &Navigation {
            list-style: none;
            display: flex;
            align-items: center;
            margin: 0;
            padding: 0;

            @include media-breakpoint-down($mobile-grid) {
                flex-direction: column;
                box-shadow: 0 40px 80px 0 rgba(0, 0, 0, 0.25);
                border-radius: 0 0 20px 20px;
                overflow: hidden;
                position: absolute;
                width: 100%;
                z-index: 999;
                background: #fff;
                transition: $transition;
                opacity: 0;
                transform: translateY(20px);
                visibility: hidden;

                &Opened & {
                    opacity: 100%;
                    transform: translateY(0);
                    visibility: visible;
                }
            }

            @include media-breakpoint-down($mobile-grid) {
                &Wrapper {
                    position: relative;
                    width: 100%;
                }

                &Opened &Select {
                    border-radius: 12px 12px 0 0;
                }
            }

            &Select {
                display: none;
                align-items: center;
                position: relative;
                border: 1px solid $input-border-color;
                border-radius: 12px;
                margin-top: 20px;
                padding: 0 18px;
                width: 100%;

                @include media-breakpoint-down($mobile-grid) {
                    display: flex;
                }

                label {
                    position: absolute;
                    font-size: 0.9em;
                    top: 0;
                    left: 15px;
                    transform: translateY(-50%);
                    font-weight: 600;
                    color: $second-font-color;
                    background: #fff;
                    padding: 0 4px;
                }

                &Label {
                    display: flex;
                    align-items: center;
                    flex: 1 1 0;
                    line-height: 50px;
                    margin: 0;
                    font-weight: 500;
                }

                span {
                    width: 15px;
                    height: 15px;
                    border-radius: 0 5px 0 0;
                    border-width: 3px 3px 0 0;
                    border-color: $primary-color;
                    border-style: solid;
                    transform: rotate(45deg);
                }
            }

            li {
                padding: 13px 20px 11px;
                color: #6E6893;
                font-size: 0.9em;
                position: relative;
                cursor: pointer;
                transition: $transition;
                border-style: solid;
                border-width: 1px 1px 0 1px;
                border-color: transparent;
                user-select: none;

                @include media-breakpoint-down($mobile-grid) {
                    width: 100%;
                }

                &:hover {
                    color: $third-color;
                }
            }
        }

        @include media-breakpoint-up($mobile-grid) {
            &Active {
                background: #fff;
                border-color: #D9D5EC !important;
                border-radius: 12px 12px 0 0;
                padding-left: 30px !important;
                padding-right: 30px !important;
                font-weight: 500;
                color: $third-color !important;
                box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.06);
            }
        }

        @include media-breakpoint-down($mobile-grid) {
            &Active {
                font-weight: 600;
                color: $black-color !important;
                background: rgba(0, 0, 0, 0.1);
            }
        }
    }

    &Content {
        box-shadow: 0 5px 12px rgba(0, 0, 0, 0.12);
        padding: 45px 30px 35px;
        background: #fff;
        border-radius: 12px;
        border: 1px solid #D9D5EC;
        margin-top: -1px;
        transition: $transition;

        @include media-breakpoint-down($mobile-grid) {
            padding: 20px 0;
            box-shadow: unset;
            border: none;
        }
    }
}