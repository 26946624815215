// Utilities
@import "utilities/variables";
@import "utilities/animations";
@import "global/button";
@import "global/modal";
@import "global/searchField";
@import "global/notification";
@import "global/switch";
@import "global/tooltip";
@import "global/form";
@import "global/layout";
@import "global/searchInput";
@import "global/editor";

// Override bootstrap variables
$nav-link-hover-color: $fourth-color;
$nav-tabs-link-active-bg: $fourth-color;
$nav-tabs-link-active-border-color: $fourth-color;
$body-bg: #313436;

/*$h1-font-size: 4.375rem;
$h2-font-size: 3.4375rem;
$h3-font-size: 2.8125rem;
$h4-font-size: 2rem;
$h5-font-size: 1.5625rem;
$h6-font-size: 1.25rem;*/

// Required
@import "bootstrap/scss/bootstrap";

// Include custom variable default overrides here
.col-xl-1half {
  @include make-col-ready();

  @include media-breakpoint-up(xl) {
    @include make-col(1.6);
  }
}

.col-xl-10half {
  @include make-col-ready();

  @include media-breakpoint-up(xl) {
    @include make-col(10.4);
  }
}

.col-xl-2half {
  @include make-col-ready();

  @include media-breakpoint-up(xl) {
    @include make-col(2.8);
  }
}

.col-xl-9half {
  @include make-col-ready();

  @include media-breakpoint-up(xl) {
    @include make-col(9.2);
  }
}

.col-lg-2half {
  @include make-col-ready();

  @include media-breakpoint-up(lg) {
    @include make-col(2.8);
  }
}

.col-lg-9half {
  @include make-col-ready();

  @include media-breakpoint-up(lg) {
    @include make-col(9.2);
  }
}



.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #333;
  border-color: #333;
}

//global definitions

@font-face {
  font-family: "Panton";
  src: url("./typography/Panton-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Panton";
  src: url("./typography/ofont.ru_Panton.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Panton";
  src: url("./typography/Panton-BlackCaps.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Poppins";
  src: url("./typography/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Poppins";
  src: url("./typography/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: url("./typography/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Inter";
  src: url("./typography/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: url("./typography/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Inter";
  src: url("./typography/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Inter";
  src: url("./typography/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
}

$custom-colors: (
  "primary-color": #f2b000,
  "second-color": #0093e9,
  "third-color": #2f3132,
  "fourth-color": #ff6a67,
  "fifth-color": #ffffff,
  "sixth-color": #efefef,
);

// Merge the maps with bootstrap
$theme-colors: map-merge($theme-colors, $custom-colors);

body,
html {
  position: relative;
  font-family: Inter, sans-serif;
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Panton", sans-serif;
  font-style: normal;
  font-weight: 900;
}
h6 {
  font-family: "Panton", sans-serif;
  font-style: normal;
  font-weight: 400;
}

a {
  text-decoration: none;
  color: $third-color;
}

button {
  font-family: "Panton", sans-serif !important;
}

.form-control input, input.form-control, .form-control select, select.form-control, .form-control textarea, textarea.form-control {
  border-radius: 5px;
  transition: border 0.1s linear;

  &:focus {
    background-color: white;
    border: 2px solid $primary-color;
    box-shadow: $box-shadow;
  }
}
.form-control {
  background-color: white;
}
input {
  &:not(:last-of-type):not(:first-of-type) {
    margin-left: 3px;
    margin-right: 3px;
  }
  &:first-of-type:nth-last-of-type(2) {
    margin-right: 3px;
  }
  &:last-of-type:nth-of-type(2) {
    margin-left: 3px;
  }
}

.justify-content-space-around {
  justify-content: space-around;
}
