// Utilities
@import "styles/utilities/variables";
@import "styles/utilities/animations";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.cart {
    padding: 30px 45px;
    position: relative;
    background: url("/assets/kamat-side.svg"), url("/assets/background.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: top 60px right 0px, top 0px right 0px;
    background-size: 35px auto, 400px 300px;
    border-top-right-radius: 30px;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down($mobile-grid) {
        background: none;
        padding: 30px 20px;
    }

    &Header {
        display: flex;
        align-items: flex-start;
        padding-right: 300px;
        justify-content: space-between;

        @include media-breakpoint-down($mobile-grid) {
            display: none;
        }

        & > div {
            display: flex;
            align-items: flex-start;
            flex: 1 1 30%;
            max-width: 30%;

            @include animate(boxIn, 300ms) {
                from {
                    transform: translateY(-40px);
                    opacity: 0;
                }
                to {
                    transform: translateY(0);
                    opacity: 1;
                }
            }

            &:nth-child(2) {
                animation-duration: 500ms;
            }

            &:nth-child(3) {
                animation-duration: 700ms;
            }

            span {
                font-size: 28px;
                line-height: 1;
                font-weight: 700;
                color: $seventh-color;
                margin-top: 5px;
                margin-right: 20px;
            }

            p {
                font-weight: 600;
                font-size: 19px;
                line-height: 22px;
                margin: 0;
            }
        }
    }

    &Title {
        margin: 60px 0 40px;
        display: flex;
        align-items: center;

        @include media-breakpoint-down($mobile-grid) {
            margin: 10px 0 40px;
            flex-direction: column;
            align-items: flex-start;
        }

        h1 {
            font-size: 32px;
            margin: 0;
            line-height: 1;

            small {
                color: $third-font-color;
                font-size: 18px;
                margin-left: 10px;

                @include media-breakpoint-down($mobile-grid) {
                    display: block;
                    margin: 10px 0 0 0;
                }
            }
        }

        & > button {
            margin-left: 30px;

            @include media-breakpoint-down($mobile-grid) {
                margin-left: 0;
                margin-top: 15px;
                width: 100%;
            }
        }
    }

    &Success {
        &Alert {
            background: #d6edd3;
            color: #275D3B;
            display: inline-flex;
            align-items: center;
            padding: 20px 25px;
            border-radius: 1rem;

            p {
                line-height: 1;
                margin: 0;
                padding: 0;
            }

            svg {
                font-size: 32px;
                margin-right: 15px;
                color: #69b060;
            }
        }

        &Resume {
            margin-top: 40px;
            display: flex;
            align-items: center;

            @include media-breakpoint-down($mobile-grid) {
                flex-direction: column;
            }

            img {
                max-width: 350px;

                @include media-breakpoint-down($mobile-grid) {
                    max-width: 300px;
                }
            }

            p {
                margin-bottom: 0;
                padding: 0;
                line-height: 1;
            }

            &Body {
                margin-left: 50px;

                @include media-breakpoint-down($mobile-grid) {
                    margin-left: 0;
                    margin-top: 30px;
                }

                &Helper {
                    margin-top: 25px;
                    color: $second-font-color;

                    & + & {
                        margin-top: 4px;
                    }

                    a {
                        font-weight: 700;
                        color: $seventh-color;
                    }
                }
            }
        }
    }

    &Body {
        flex: 1 1 0;

        &Empty {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 80px;

            img {
                max-width: 150px;
            }

            p {
                margin-top: 20px;
            }
        }
    }

    &Item {
        background: #fff;
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
        border-style: solid;
        border-color: #EBE6ED;
        border-width: 1px 1px 4px 1px;
        border-radius: 8px 2.8rem 8px 2.8rem;
        max-width: 1250px;
        display: flex;
        align-items: flex-start;
        padding: 30px 45px 30px 30px;
        font-size: 14px;
        transition: $transition;

        @include media-breakpoint-down($mobile-grid) {
            flex-direction: column;
            padding: 20px;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
            border-radius: 1rem;
        }

        &Invalid {
            background: #fef4f3;
            box-shadow: 0 0 40px rgba(0, 0, 0, 0.15), 0 0 0 3px #f8c9c5 inset;
        }

        &Error {
            background: #fadbd8;
            color: #b93d30;
            display: flex;
            align-items: center;
            padding: 7px 10px;
            border-radius: 12px;
            margin-bottom: 10px;

            p {
                line-height: 1;
                margin: 0;
                font-family: Inter, sans-serif;
                font-weight: 500;
            }

            svg {
                font-size: 22px;
                margin-right: 10px;
            }
        }

        &Sidebar {
            margin-right: 30px;
            font-family: Inter, sans-serif;

            @include media-breakpoint-down($mobile-grid) {
                width: 100%;
                margin: 0;
            }

            a {
                color: #9f9f9f !important;
                transition: $transition;
                cursor: pointer;
                font-weight: 500;

                @include media-breakpoint-down($mobile-grid) {
                    display: table;
                    margin: 0 auto;
                }

                img {
                    width: 14px;
                    vertical-align: -1px;
                    margin-right: 7px;
                }

                &:hover {
                    color: $danger-color !important;
                }
            }
        }

        &Pic {
            width: 180px;
            height: 130px;
            background: #eaeaea;
            position: relative;
            border-radius: 12px;
            margin-bottom: 20px;

            @include media-breakpoint-down($mobile-grid) {
                width: 100%;
                height: auto;
                padding: 20px 0;

                img {
                    display: block;
                    max-width: 90%;
                    max-height: 140px;
                    margin: 0 auto;
                }
            }

            @include media-breakpoint-up($mobile-grid) {
                img {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    position: absolute;
                    max-width: 100px;
                    max-height: 100px;
                }
            }
        }

        &Row, &RowWithoutLabel {
            display: flex;
            align-items: center;
            font-family: Inter, sans-serif;

            @include media-breakpoint-down($mobile-grid) {
                flex-wrap: wrap;
            }

            & > p {
                line-height: 1;
                margin: 0;
                color: #9f9f9f;
                width: 90px;

                @include media-breakpoint-down($mobile-grid) {
                    flex: 1 1 100%;
                    margin: 20px 0 8px;
                    font-weight: 500;
                }
            }
        }

        &Name {
            font-size: 22px;
            margin-bottom: 15px;

            &Mobile {
                display: none;
            }

            @include media-breakpoint-down($mobile-grid) {
                display: none;

                &Mobile {
                    display: block;
                    margin-bottom: 20px;
                }
            }
        }

        &Row + &Row {
            margin-top: 15px;

            @include media-breakpoint-down($mobile-grid) {
                margin-top: 0;
            }
        }

        &Row + &RowWithoutLabel, &RowWithoutLabel + &RowWithoutLabel {
            margin-top: 5px;

            @include media-breakpoint-down($mobile-grid) {
                margin-top: 0;
            }
        }

        &RowWithoutLabel {
            padding-left: 90px;

            @include media-breakpoint-down($mobile-grid) {
                padding-left: 0;
            }
        }

        &Body {
            flex: 1 1 0;
        }

        &Config {
            display: flex;
            align-items: flex-start;

            @include media-breakpoint-down($mobile-grid) {
                flex-direction: column;
            }

            & > * {
                flex: 1 1 0;
            }
        }

        &Location {
            padding-left: 90px;

            @include media-breakpoint-down($mobile-grid) {
                padding-left: 0;
                padding-top: 30px;
                margin-top: 30px;
                border-top: 2px dashed $input-border-color;
            }

            & > p {
                font-family: Inter, sans-serif;
                font-weight: 500;
            }

            &Address, &Select {
                border: 1px solid #C4C4C4;
                font-family: "Open Sans", sans-serif;
            }

            &Select {
                width: 100%;
                display: flex;
                align-items: center;
                height: 44px;
                cursor: pointer;
                transition: $transition;
                padding: 0 20px;
                border-radius: 8px;

                @include media-breakpoint-down($mobile-grid) {
                    height: 64px;
                }

                &:hover {
                    color: $eighth-color;
                }

                p {
                    margin: 0;
                    line-height: 1;
                    flex: 1 1 0;
                }

                &Icon {
                    color: $seventh-color;
                    font-size: 24px;
                    margin-right: 12px;
                }

                &Arrow {
                    font-size: 18px;
                    color: $gray-color;
                }
            }

            &Address {
                padding: 20px;
                display: flex;
                align-items: center;
                border-radius: 12px;

                @include media-breakpoint-down($mobile-grid) {
                    flex-wrap: wrap;
                }

                & > div {
                    flex: 1 1 0;
                }

                & > svg {
                    font-size: 25px;
                    margin-right: 20px;
                    color: $primary-color;
                    opacity: 80%;
                }

                a {
                    color: #9f9f9f !important;
                    transition: $transition;
                    cursor: pointer;
                    font-family: Inter, sans-serif;
                    font-weight: 500;

                    @include media-breakpoint-down($mobile-grid) {
                        width: 100%;
                        margin-top: 10px;
                        margin-left: 35px;
                    }

                    img {
                        width: 20px;
                        vertical-align: -5px;
                        margin-right: 2px;
                    }

                    &:hover {
                        color: $eighth-color !important;
                    }
                }

                p {
                    margin: 0;
                    line-height: 1.2em;

                    &:first-child {
                        font-weight: 600;
                    }
                }
            }
        }

        &InputPicker {
            border: none !important;

            input {
                width: 110px;

                &:focus {
                    outline: none !important;
                    box-shadow: none !important;
                    border-color: $seventh-color;
                }
            }
        }

        &InputPicker input, &Option {
            height: 42px;
            line-height: 42px;
            border: 1px solid #C4C4C4;
            border-radius: 8px;
            padding: 0 15px;
            font-family: "Open Sans", sans-serif;
        }

        @include media-breakpoint-down($mobile-grid) {
            &Option + &Sep {
                display: none;
            }
        }

        &Option {
            transition: $transition;
            cursor: pointer;
            white-space: nowrap;

            @include media-breakpoint-down($mobile-grid) {
                width: 100%;
                margin-bottom: 10px;
            }

            &:hover {
                color: $seventh-color;
            }

            svg {
                margin-left: 5px;
                font-size: 1.2em;

                @include animate(in, 200ms) {
                    from {
                        transform: scale(0);
                    }
                    to {
                        transform: scale(1);
                    }
                }
            }

            &Active {
                background: #FFC523;

                &:hover {
                    opacity: 70%;
                    color: #000;
                }
            }
        }

        &Sep {
            width: 15px;
            height: 1px;
            margin: 0 10px;
            background: #C4C4C4;
        }
    }

    &Item + &Item {
        margin-top: 30px;
    }

    &Footer {
        margin-top: 50px;
        padding-bottom: 20px;
        display: flex;
        align-items: center;

        @include media-breakpoint-down($mobile-grid) {
            flex-direction: column;
        }

        &Btn {
            width: 260px;

            @include media-breakpoint-down($mobile-grid) {
                width: 100%;
            }

            @include animate(shakeIt, 5s, true, 6s) {
                0% {
                    transform: rotate(0);
                }
                2% {
                    transform: rotate(1deg);
                }
                4% {
                    transform: rotate(-1deg);
                }
                6% {
                    transform: rotate(1deg);
                }
                8% {
                    transform: rotate(-1deg);
                }
                10% {
                    transform: rotate(0);
                }
            }

            &:hover {
                animation-play-state: paused;
            }

            &Disabled {
                animation-play-state: paused;
            }
        }

        &Back {
            font-family: Inter, sans-serif;
            font-weight: 500;
            color: $font-color !important;
            margin-left: 40px;

            @include media-breakpoint-down($mobile-grid) {
                margin-left: 0;
                margin-top: 20px;
            }
        }
    }
}