// Utilities
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "styles/utilities/variables";

.form {
  margin: 20px 0;

  input {
    margin-left: 0 !important;
  }
}

.errorText {
  color: $danger;
}