@import "styles/utilities/variables";

.siteListingModal {
    width: 1020px;
    padding: 25px 35px 20px;

    @include media-breakpoint-down($mobile-grid) {
        width: 100%;
        padding: 25px;
    }

    &Title {
        font-family: Inter, sans-serif;
        font-weight: 700;
        font-size: 19px;
        text-transform: uppercase;
        margin-bottom: 45px;
        padding-top: 10px;
    }

    &Empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        svg {
            color: rgba(0, 0, 0, 0.16);
            font-size: 55px;
            margin: 30px 0 10px;
        }
    }

    &Wrapper {
        display: flex;
        align-items: stretch;

        @include media-breakpoint-down($mobile-grid) {
            flex-direction: column;
        }

        & > * {
            flex: 1 1 0;
        }

        & > * + * {
            padding-left: 40px;
            border-left: 2px dashed #dae1eb;

            @include media-breakpoint-down($mobile-grid) {
                border: none;
                padding-left: 0;
            }
        }
    }

    &Form {
        max-width: 48%;
        padding-bottom: 15px;

        @include media-breakpoint-down($mobile-grid) {
            max-width: unset;
            order: 0;
            margin-bottom: 30px;
            padding-bottom: 30px;
            border-bottom: 2px dashed #dae1eb;
        }

        :global .site-manager, :global .site-manager-phone {
            flex: 1 0 100%;
            width: 100%;
            max-width: unset;
        }

        :global .site-manager-phone {
            margin-top: 25px;
        }

        :global .km-flex {
            flex-wrap: wrap;
        }

        &Btn {
            width: 100%;
            margin-top: 30px;
            font-size: 15px;
        }
    }

    &Listing {
        max-height: 450px;
        overflow: auto;
        padding-right: 30px;
        margin-right: 10px;

        @include media-breakpoint-down($mobile-grid) {
            max-height: unset;
            order: 1;
            padding-right: 0;
        }
    }

    &Address {
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        border-bottom: 1px solid #d1dae6;

        @include media-breakpoint-down($mobile-grid) {
            flex-wrap: wrap;

            & > button {
                flex: 1 1 100%;
                margin-top: 20px;
            }
        }

        &Icon {
            color: $seventh-color;
            font-size: 28px;
            margin-right: 25px;
            opacity: 50%;
        }

        &Content {
            flex: 1 1 0;
        }

        p {
            line-height: 1.1em;
            margin: 0;
            font-family: "Open Sans", sans-serif;

            &:first-child {
                font-weight: 500;
            }
        }
    }

    &Address + &Address {
        padding-top: 10px;
    }
}