@import "styles/utilities/variables";
@import "styles/utilities/animations";
@import "~bootstrap/scss/mixins/_breakpoints";

.offerDetailConditionsModal {
    padding: 30px 35px;
    width: 580px;

    h2 {
        font-size: 25px;
        text-align: center;
        margin-bottom: 20px;
    }

    &Text {
        max-height: 200px;
        overflow-y: auto;
        padding: 10px 0;
        white-space: pre-line;
    }
}