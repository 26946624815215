@import "styles/utilities/variables";

.productModal {
    top: 0;
    padding: 0;
    border-radius: 0;
    display: flex;
    justify-content: center;
    min-height: 100%;

    form {
        width: 100%;
        max-width: 750px;
        padding: 40px 50px 40px;
    }

    @include media-breakpoint-down($mobile-grid) {
        top: unset;
        padding: 30px;
        overflow-y: auto;
    }

    h4 {
        font-family: Inter, sans-serif;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 20px;

        @include media-breakpoint-down($mobile-grid) {
            margin-bottom: 40px;
        }
    }

    &Row {
        @include media-breakpoint-down($mobile-grid) {
            flex-wrap: wrap;
        }

        & > * {
            width: 48% !important;

            @include media-breakpoint-down($mobile-grid) {
                width: 100% !important;
            }
        }

        @include media-breakpoint-down($mobile-grid) {
            & > * + * {
                margin-top: 25px;
            }

            &Checkbox {
                flex-direction: column;
                align-items: flex-start !important;

                & > * + * {
                    margin-top: 10px;
                    margin-left: 0 !important;
                }
            }
        }
    }

    &Site {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 15px 0 50px;

        & > p {
            font-size: 0.9em;
            margin: 0;

            & > svg {
                font-size: 18px;
                vertical-align: -3px;
                margin-right: 3px;
                color: $second-color;
            }
        }
    }

    &Header {
        display: flex;
        align-items: center;
        padding-bottom: 40px;
        margin-bottom: 55px;
        border-bottom: 2px dashed rgba(0, 0, 0, 0.14);

        &MissingIcon {
            background: #e1e8f1;
            color: #6e767e;
            font-size: 48px;
            text-align: center;
            line-height: 85px;
        }

        &Icon, &MissingIcon {
            width: 90px;
            height: 90px;
            margin-right: 30px;
            border-radius: 1rem;
        }

        &Icon {
            background: #e9eff5;
            position: relative;

            @include media-breakpoint-down($mobile-grid) {
                display: none;
            }

            img {
                max-width: 75%;
                max-height: 75%;
                top: 50%;
                left: 50%;
                position: absolute;
                transform: translate(-50%, -50%);
            }
        }

        &Content {
            flex: 1 1 0;
        }
    }
}