@import "styles/utilities/variables";

.rentalModal {
    max-width: 1050px;
    background-color: #fff !important;
    background-image: linear-gradient(to bottom, #fff 50%, #e6e6e6 90%);
    padding: 30px 45px 45px;
    font-family: "Inter", sans-serif;
    font-size: 15px;

    &Header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 50px;
        padding-top: 10px;

        h1 {
            font-family: "Inter", sans-serif;
            font-weight: bold;
            font-size: 2.8em;
        }

        &Status {
            font-weight: 700;
            text-transform: uppercase;
            font-size: 0.9em;
            padding: 5px 10px;
            border-radius: 12px;
            margin-right: 8px;

            &:before {
                content: '';
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 5px;
                margin-right: 7px;
            }
        }

        &Tools {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            list-style: none;
            margin: 0;
            padding: 0 15px 0 0;

            li {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                cursor: pointer;

                &:hover {
                    img {
                        transform: scale(1.1);
                    }

                    p {
                        color: $eighth-color;
                    }
                }

                img {
                    transition: $transition;
                    height: 40px;
                }

                p {
                    margin: 10px 0 0;
                    text-transform: uppercase;
                    font-family: Panton, sans-serif;
                    font-size: 0.8em;
                    font-weight: 700;
                    max-width: 120px;
                    line-height: 1.1em;
                    text-align: center;
                    transition: $transition;
                }
            }

            li + li {
                margin-left: 20px;
            }
        }

        &Confirm {
            p {
                font-weight: 500;
                margin-bottom: 8px;
                max-width: 600px;
                line-height: 1;
                text-align: right;

                svg {
                    color: $seventh-color;
                    height: 18px;
                    vertical-align: -4px;
                    margin-right: 2px;
                }
            }

            &Wrapper {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                a {
                    border-style: solid;
                    border-width: 2px;
                    border-radius: 10px;
                    padding: 0 14px;
                    font-weight: 500;
                    cursor: pointer;
                    transition: $transition;
                    display: flex;
                    align-items: center;
                    height: 42px;

                    &:global(.disabled) {
                        cursor: default;
                        user-select: none;
                        user-focus: none;
                        pointer-events: none;
                    }
                }

                a + a {
                    margin-left: 10px;
                }
            }

            &Ok {
                background: $primary-color;
                border-color: $primary-color;
                color: $third-color !important;

                &:hover {
                    opacity: 70%;
                }
            }

            &Cancel {
                border-color: $third-color;

                &:hover {
                    color: #fff;
                    background: $third-color;
                }
            }
        }
    }

    &Body {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        & > div {
            min-width: 20%;
        }

        p {
            margin: 0;
            line-height: 1.4em;
            position: relative;

            img {
                position: absolute;
                left: -27px;
                height: 19px;
            }
        }

        h4 {
            font-size: 16px;
            font-family: "Inter", sans-serif;
            margin-bottom: 15px;
        }
    }
}

.paid {
    background: #CDFFCD;
    color: #007F00;

    &:before {
        background: #007F00;
    }
}