@import "styles/utilities/variables";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.settings {
    margin-top: 25px;
    z-index: 1;
    position: relative;

    &Title {
        text-transform: uppercase;
        font-family: "Panton", sans-serif;
        font-size: 24px;
        margin-bottom: 30px;
    }

    &SectionTitle {
        font-size: 1.1em;
        font-weight: 500;
        margin: 0 0;
    }

    &Helper {
        color: $third-font-color;
        font-size: 0.9em;

        svg {
            margin-right: 3px;
            opacity: 80%;
        }
    }

    &SocietyLogo {
        display: flex;
        align-items: center;

        &Img {
            border: 1px solid $input-border-color;
            padding: 8px;
            border-radius: 12px;
            margin-right: 30px;
        }
    }
}