// Utilities
@import "styles/utilities/variables";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.productCard {
    background-color: $fifth-color;
    box-shadow: inset 0 -1px 0 #CBD5E0;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    min-width: 780px;
    display: flex;
    align-items: flex-start;
    padding: 20px 10px;
    position: relative;

    @include media-breakpoint-down($mobile-grid) {
        flex-direction: column;
        padding: 0 0 20px;
        min-width: unset;
    }

    &Technic, &Options {
        flex: 1 1 22%;

        @include media-breakpoint-down($mobile-grid) {
            flex: unset;
        }

        p {
            a {
                color: $second-color;
            }
        }

        p + p {
            margin-top: 4px;
        }

        @include media-breakpoint-down($mobile-grid) {
            p + p {
                margin-top: 2px;
            }
        }
    }

    @include media-breakpoint-down($mobile-grid) {
        &Technic {
            margin-top: 10px;
        }
    }

    &Name {
        flex: 1 1 15%;
        position: relative;

        @include media-breakpoint-down($mobile-grid) {
            width: 100%;
            padding-right: 55px;
        }

        h5, h6 {
            font-family: "Inter", sans-serif;
        }

        h5 {
            font-size: 17px;

            @include media-breakpoint-down($mobile-grid) {
                margin-bottom: 0;
            }
        }

        h6 {
            font-size: 15px;

            @include media-breakpoint-down($mobile-grid) {
                margin-bottom: 10px;
            }
        }
    }

    &Toggle {
        background: #a4b0be;
        width: 45px;
        height: 45px;
        position: absolute;
        top: -5px;
        right: 15px;
        border-radius: 14px;
        opacity: 80%;
        display: none;

        @include media-breakpoint-down($mobile-grid) {
            display: block;
        }

        & > span {
            width: 15px;
            height: 15px;
            border-radius: 0 5px 0 0;
            border-width: 3px 3px 0 0;
            border-color: #fff;
            border-style: solid;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-60%, -50%) rotate(45deg);
            transition: $transition;
        }
    }

    &Opened &Toggle span {
        transform: translate(-50%, -65%) rotate(135deg);
    }

    @include media-breakpoint-down($mobile-grid) {
        &:not(&Opened) &Technic, &:not(&Opened) &Options {
            display: none;
        }
    }

    &Tools {
        align-self: center;

        & > * + * {
            margin-left: 10px;
        }

        @include media-breakpoint-down($mobile-grid) {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
        }
    }

    @include media-breakpoint-down($mobile-grid) {
        &:not(&Opened) &Tools {
            justify-content: flex-start;
            margin-top: 0;
        }
    }

    p {
        margin: 0;
        line-height: 24px;

        @include media-breakpoint-down($mobile-grid) {
            line-height: 20px;
        }

        img {
            vertical-align: -4px;
            margin-right: 5px;
        }
    }
}