.form-switch {
    .form-check-input {
        height: 28px !important;
        width: 46px !important;
        margin-top: 0 !important;
        vertical-align: -8px;
        cursor: pointer;

        &:checked {
            background-color: #2F3132 !important;
        }

        &:not(:checked) {
            background-color: #c8d6e5;
            border-color: #c8d6e5;
        }

        &:focus {
            outline: none !important;
            box-shadow: none !important;
        }
    }

    label {
        margin-left: 12px;
    }
}