// Utilities
@import "styles/utilities/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.offerDetail {
    background: #fff;
    position: relative;
    border: 1px solid rgba(209, 209, 209, 0.5);
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
    border-radius: 18px;
    padding: 20px 30px;
    margin-right: 40px;
    animation-fill-mode: both;
    animation-name: detailIn;
    animation-duration: 250ms;
    margin-left: 40px;

    @include media-breakpoint-down($mobile-grid) {
        margin: 0;
        border-radius: 0;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
        padding-top: 50px;
    }

    &Close {
        position: absolute;
        top: 10px;
        right: 20px;
        font-size: 30px;
        display: none;

        @include media-breakpoint-down($mobile-grid) {
            display: block;
        }
    }

    h2 {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;

        @include media-breakpoint-down($mobile-grid) {
            line-height: 24px;
            font-size: 18px;
        }

        & + p {
            margin-top: 8px;
            color: rgba(169, 169, 169, 1);
            font-size: 14px;
        }
    }

    &Expired {
        text-align: center;
        color: #c0392b;
        font-weight: 500;
    }

    &Products {
        list-style: none;
        padding: 0;
        margin: 30px 0;
    }

    &Product {
        display: flex;
        align-items: stretch;

        @include media-breakpoint-down($mobile-grid) {
            flex-direction: column;
        }

        &Img {
            width: 100px;
            height: 100px;
            background: #eaeaea;
            position: relative;
            margin-right: 30px;
            border-radius: 1rem;
            flex-shrink: 0;

            @include media-breakpoint-down($mobile-grid) {
                width: 100%;
                height: 120px;
                margin-bottom: 20px;
                margin-right: 0;
            }
        }

        img {
            max-width: 70px;
            max-height: 70px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &Info {
            h5 {
                font-weight: bold;
                font-size: 17px;
                margin: 0  0 5px 0;
                color: rgba(30, 14, 98, 1);
            }

            p {
                color: rgba(21, 20, 57, 0.7);
                margin: 0;
                font-size: 0.9em;

                svg {
                    margin-right: 5px;
                    color: $primary-color;
                }
            }
        }
    }

    &Product + &Product {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid rgba(235, 234, 237, 1);
    }

    &Price {
        list-style: none;
        margin: 40px 0 20px;
        padding: 40px 0 0;
        border-top: 2px rgba(0, 0, 0, 0.15) dashed;

        li {
            display: flex;
            align-items: center;

            font-family: "Poppins";
            font-weight: 600;
            font-size: 15px;

            p {
                margin: 0;
            }

            p:first-child {
                flex: 1 1 0;
            }

            p + p {
                margin-left: 30px;
            }
        }

        li + li {
            margin-top: 10px;
        }

        &Total {
            margin-top: 40px !important;

            p {
                font-size: 24px;
                line-height: 1;
                margin: 0;
            }

            p:last-child {
                font-size: 25px;
                color: rgb(106, 152, 60);
            }

            &HT {
                margin: 0 !important;

                p {
                    color: rgba(169, 169, 169, 1);
                    flex: 1 1 0;
                    text-align: right;
                }
            }
        }
    }

    &ConditionsBtn {
        font-size: 18px;
        font-family: Panton, sans-serif;
        font-weight: bold;
        display: table;
        margin: 0 auto 20px;
        cursor: pointer;
    }

    &Btn {
        display: table;
        margin: 40px auto 0;

        @include media-breakpoint-down($mobile-grid) {
            width: 100%;
        }
    }

    &CancelBtn, &CancelConfirmBack {
        background: #bdc3c7;

        &:hover, &:active {
            background: #979c9f;
        }
    }

    &CancelConfirm {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;

        p {
            flex: 1 1 100%;
            text-align: center;
        }

        button {
            margin: 0 0;
        }

        button + button {
            margin-left: 15px;
        }

        &Ok {
            background: #e55039;

            &:hover, &:active {
                background: #b7402e;
            }
        }
    }
}

@keyframes detailIn {
    from {
        transform: translateY(-15px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@include media-breakpoint-down(xl) {
    .offerDetail {
        margin-bottom: 40px;
    }
}