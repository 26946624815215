// Utilities
@import "styles/utilities/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.container {
    position: relative;
    background: url("./assets/kamat-side.svg"), url("./assets/background.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: top 60px right 0px, top 0px right 0px;
    background-size: 35px auto, 400px 300px;

    @include media-breakpoint-down($mobile-grid) {
        background: none;
    }
}

@include media-breakpoint-down(xl) {
    .container {
        border-top-right-radius: 15px;
    }
}