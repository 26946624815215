.km-search {
    display: flex;
    align-items: center;
    background: #ffe07d;
    padding: 0 18px 0 12px;
    border-radius: 12px;
    flex: 1 1 0;
    max-width: 500px;

    img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }

    input {
        color: #6E6893;
        background: transparent;
        padding: 0;
        border: none;
        box-shadow: none;
        outline: none;
        font-family: "Inter", sans-serif;
        line-height: 44px;
        font-size: 14px;
        flex: 1 1 0;
    }
}